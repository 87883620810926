.searchBar {
  display: flex;
  margin-bottom: 15px;

  .search {
    padding: 0 10px;
    border: 1px solid grey;
    border-radius: 4px;
    flex-grow: 1;

    .searchInput {
      flex-grow: 1;
    }

    .searchCount {
      align-self: center;
    }
  }
}
