@import '../..//abstracts';

.formTooltip {
  font-size: 0.85rem;

  &:not(div).disabled {
    pointer-events: none;
    opacity: 0.5
  }

  .tooltipTitle,
  .tooltipSubTitle,
  .tooltipText,
  h1,
  h2,
  h3,
  h4,
  p {
    display: block;
    margin: 8px 0;
  }

  .tooltipTitle,
  .tooltipSubTitle,
  h1,
  h2,
  h3,
  h4 {
    font-weight: 500;
  }


  .tooltipTitle,
  h1 {
    font-size: 1.1rem;
    margin-bottom: 12px;
  }

  .tooltipSubTitle,
  h2,
  h3,
  h4 {
    font-size: 0.9rem;
  }

  .tooltipText,
  p {
    font-size: 0.85rem;
  }

  a {
    color: $white !important;
  }
}
