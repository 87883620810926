@import "../../abstracts";

$borderColor: #d5d5d5;

$status-neutral: $grey;
$status-safe: $green;
$status-alert: $yellow;
$status-danger: $red;

.task {
    display: flex;
    flex-direction: column;
    padding: {
        top: 16px;
        bottom: 16px;
    }
    border: {
        bottom: 1px solid $borderColor;
        right: 1px solid $borderColor;
    }
    cursor: pointer;
    transition: background-color 0.2s linear;

    &:hover {
        background-color: lighten($borderColor, 12.5%);
    }

    &,
    & ~ div:not(.task) {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 355px;
        min-width: 355px;
        max-width: 715px;
        padding: {
            left: 16px;
            right: 16px;
        }
    }

    & ~ div:not(.task) { 
        border-right: 1px solid transparent;
        order: 100;
    }


    .taskTitle {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        font-size: 1.1rem;
        padding-bottom: 16px;

        & > span {
            width: 100%;
        }
    }

    span.taskTitleLabel {
        display: block;
        padding: {
            top: 12px;
            bottom: 12px;
            left: 16px;
            right: 16px;
        }
        margin: {
            top: -16px;
            left: -16px !important;
            right: -16px;
            bottom: 8px;
        }
        width: calc( 100% + 32px );
        background-color: $status-neutral;
        color: $white;

        &.light {
            background-color: lighten($status-neutral, 42%);
            color: $black;
        }

        &.inv {
            padding-bottom: 0px;
            margin-bottom: 0px;
            background-color: transparent;
            color: $status-neutral;
        }
    }

    &.status-SUCCEEDED,
    &.status-ALLOCATION_CONFIRMED,
    &.status-COMPLETED,
    &.status-OK {
        .taskTitleLabel {
            background-color: $status-safe;

            &.light {
                background-color: lighten($status-safe, 33%);
            }

            &.inv {
                background-color: transparent;
                color: $status-safe;
            }
        }
    }

    // Alert color
    &.status-ALLOCATION_CONFIRMATION,
    &.status-COMPLIANCE_REVIEW,
    &.status-WAITING_FOR_VIDEO,
    &.status-WAITING_FOR_ALLOCATION,
    &.status-DELETED,
    &.status-RESUBMISSION,
    &.status-WAITING_FOR_SOMETHING {
        .taskTitleLabel {
            background-color: $status-alert;

            &.light {
                background-color: lighten($status-alert, 33%);
            }

            &.inv {
                background-color: transparent;
                color: $status-alert;
            }
        }
    }

    // Danger color
    &.status-NEW,
    &.status-VIDEO_PROVIDING_EXPIRED,
    &.status-CANCELLED,
    &.status-ALLOCATION_REFUSED,
    &.status-COMPLIANCE_REVIEW_CONFIRMATION,
    &.status-BLOCKED,
    &.status-GOING_TO_EXPIRE {
        .taskTitleLabel {
            background-color: $status-danger;

            &.light {
                background-color: lighten($status-danger, 33%);
            }

            &.inv {
                background-color: transparent;
                color: $status-danger;
            }
        }
    }

    .taskRemainingTime {
        width: 100%;
        display: flex;
        align-items: center;
        line-height: 22px;
        font-size: 1rem;

        b {
            display: inline-block;
            margin-left: 8px;
        }

        div {
            margin-top: -2px;
        }
    }

    .id,
    .workOrder,
    .workOrderId {
        font-size: 1rem;
    }

    span.subtitle {
        border-bottom: 1px dashed $borderColor;
        padding: {
            left: 16px;
            right: 16px;
            bottom: 8px;
        }
        margin: {
            left: -16px;
            right: -16px;
        }
        width: calc( 100% + 32px );
        font-size: 0.975rem;
        
    }

    .taskActions {
        display: flex;
        justify-content: flex-end;
        margin-top: 6px;

        button {
            padding: 2px 8px;
            font-size: 12px;
        }
    }

    .taskCompilanceReviewPeriod {
        line-height: 22px;
        font-size: 1rem;
    }

    .taskMeta {
        span {
            display: block;
            
            padding-left: 19px;
            text-indent: -19px;
            color: $black;
            font-size: 0.9rem;

            svg {
                margin-bottom: -2px; 
                font-size: inherit;
                fill: $black;
            }
        }
    }
}

.taskDate {
    position: relative;
    display: flex;
    flex-direction: column;

    &.taskDateAccordion {
        margin: 0 !important;
    }
}
