.profile-picture-editor-wrapper {
  display: flex;
  align-items: center;

  #file-uploader {
    width: 100% !important;
    height: auto !important;
    background-color: #eaeaea;
    border-style: none !important;
    cursor: pointer;
  
    p {
      padding: 15px;
    }

    .error-message {
      padding-top: 0;
      display: flex;
    }
  }
  
  #file-uploader button {
    margin: {
      top: 10px !important;
    }
  }
}
