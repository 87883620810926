@import '../../abstracts';

@mixin profileSize($width: 30px, $height: $width) {
  width: $width;
  height: $height;
  flex-basis: $width;
  min-width: $width;
}

%size-sm {
  @include profileSize(30px);
}

%size-m {
  @include profileSize(46px);
}

%size-l {
  @include profileSize(100px);
}

%size-sm-landscape {
  @include profileSize(50px, 30px);
}

%size-m-landscape {
  @include profileSize(82px, 46px);
}

%size-l-landscape {
  @include profileSize(178px, 100px);
}

.profile-picture-wrapper {
  position: relative;
  background-color: white;
  border-radius: 6px;
  display: flex;
  border-radius: 6px;
  border: 1px solid rgba(black, 0.25);

  &.size-sm {
    @extend %size-sm;
  }

  &.size-m {
    @extend %size-m;
  }

  &.size-l {
    @extend %size-l;
  }

  &.orientation-landscape {
    &.size-sm {
      @extend %size-sm-landscape;
    }
  
    &.size-m  {
      @extend %size-m-landscape;
    }
  
    &.size-l  {
      @extend %size-l-landscape;
    }
  }
}

.profile-picture {
  position: relative;
  flex-shrink: 0;
  background-repeat:no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 6px;
  border: 1px solid rgba(black, 0.25);

  &.size-sm {
    @extend %size-sm;
  }

  &.size-m  {
    @extend %size-m;
  }

  &.size-l  {
    @extend %size-l;
  }

  &.orientation-landscape {
    &.size-sm {
      @extend %size-sm-landscape;
    }
  
    &.size-m  {
      @extend %size-m-landscape;
    }
  
    &.size-l  {
      @extend %size-l-landscape;
    }
  }
}

.placeholder-icon {
  color: #262626;
  margin: auto;

  &.size-sm {
    @extend %size-sm;
  }

  &.size-m  {
    @extend %size-m;
  }

  &.size-l  {
    @extend %size-l;
  }
}

.profile-picture-status {
  display: none;
  position: absolute;
  bottom: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;

  &.status-INACTIVE {
    display: block;
    background-color: $red;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    transform: translate(-50%, -50%);
  }

  .size-sm & {
    width: 16px;
    height: 16px;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .size-m & {
    width: 20px;
    height: 20px;
    
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .size-l & {
    width: 24px;
    height: 24px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
