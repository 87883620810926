@import '../abstracts';


.versionLink {
  display: flex;
  justify-content: flex-end;
  text-decoration: none;
}

.environment {
  position: relative;
  z-index: 15;
  align-self: flex-end;
  flex-shrink: 1;
  display: flex;
  border-radius: 4px;
  margin: {
    top: 12px;
    bottom: 12px;
    left: 5px;
    right: 5px;
  }
  color: $grey;
  font-size: 0.85rem;
  opacity: 0.85;
  height: 18px;
  line-height: 19px;

  @include breakpoint-down(695px) {
    margin: {
      left: 3px;
      right: 3px;
    }

    font-size: 0.75rem;
  }

  .environmentLabel {
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 4px 0 0 4px;
  }

  .versionLabel {
    border-radius: 0 4px 4px 0;
  }

  .environmentLabel,
  .versionLabel {
    display: block;
    padding: {
      left: 4px;
      right: 4px;
    }
    background-color: $white;
    

    &:empty {
      display: none;
    }
    
    @include breakpoint-down(695px) {
      padding: {
        left: 2px;
        right: 2px;
      }
    }
  }

  &.horizon-dev {
    .environmentLabel {
      background-color: $teal;
      color: $white;
    }
  }

  &.horizon-int {
    .environmentLabel {
      background-color: $blue;
      color: $white;
    }
  }

  &.horizon-uat {
    .environmentLabel {
      background-color: $yellow;
      color: $white;
    }
  }

  &.horizon-production {
    .environmentLabel {
      background-color: $red;
      color: $white;
    }
  }
}
