@import "../../abstracts";

$borderColor: #d5d5d5;

$status-neutral: $grey;
$status-safe: $green;
$status-alert: $yellow;
$status-danger: $red;

.taskManager {
  overflow: hidden;

  .taskManagerHeader {
      display: flex;
      justify-content: flex-end;
      padding: 16px;
      width: 100%;
      border-bottom: 1px solid $borderColor;
      margin-bottom: -1px;
      position: relative;
      z-index: 10;
  }

  .taskList {
    order: 5;
    position: relative;
    z-index: 5;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align: {
        items: stretch;
        content: stretch;
    }
    margin: {
        bottom: -1px;
        right: -1px;
    }
  
    &:empty ~ .taskDateLabel {
        display: none;
    }
  }

  .taskManagerFooter {
      border-top: 1px solid $borderColor;
      margin-top: -1px;
  }

  .taskManagerMeta {
      span {
          display: inline-block;
          color: rgba(0,0,0, 0.55);

          & + span {
              margin-left: 16px;
          }
      }

      svg {
          margin-bottom: -1px;
          fill: rgba(0,0,0, 0.55);
      }

      .open svg {
          fill: $red;
      }

      .closed svg {
          fill: $green;
      }
  }

  .taskDateAccordionSummary {
      min-height: 46px !important;
      padding: {
          left: 16px;
          right: 16px;
      }

      & > div:first-child {
          margin: {
              top: 12px !important;
              bottom: 12px !important;
          }
      }

      h6 {
          font-size: 1.15rem !important;
          line-height: 1.6em;
          display: flex;
          align-items: center;
          line-height: 2em;
      }
  }

  .taskDateLabel {
      position: absolute;
      right: calc( 100% + 1px );
      top: 0;
      border-width: 0 !important;
      padding: 8px;
      width: 80px;
      background-color: $teal;
      color: $white;
      box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.045), 0px 1px 1px 0px rgba(0,0,0,0.05), 0px 2px 1px -1px rgba(0,0,0,0.05);

      * {
          color: inherit;
      }

      @include breakpoint-down(9999px) {
          position: static;
          text-align: left;
          width: 100%;
          border: {
              width: 1px 0 0 0;
              radius: 0;
          }
          padding: {
              left: 16px;
              right: 16px;
          }
          box-shadow: 0 0 0 0 transparent;

          h6 {
              text-align: left !important;
          }
      }
  }
}
