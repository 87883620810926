@import '../../abstracts';

div.workOrderActions {
    border: 1px solid rgba(224, 224, 224, 1);

    button,
    a {
        width: auto;

        & + button,
        & + a {
            margin-left: 8px;
        }
    }

    .workOrderActionsRow {
        display: flex;
        align-items: center;

        & > div,
        & > div > div {
            width: 100%;
        }

        svg {
            // margin-left: 8px;
        }

        h2 {
            margin: {
                top: -16px;
                left: -16px;
                right: -16px;
                bottom: 16px;
            }

            padding: {
                top: 16px;
                left: 16px;
                right: 16px;
                bottom: 16px;
            }

            background-color: lighten($red, 48.5%);
            color: $red;
        }
    }
}

div.videoPlayer {
    div,
    iframe {
        max-width: 100%;
        width: 100%;
    }

    &.noVideoToLoad {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 280px;
        width: 100%;

        border-radius: 4px;
        background-color: #757575;

        svg {
            width: 80px;
            height: 80px;
            fill: #eeeeee;
        }

        p {
            color: #eeeeee;
            font-size: 18px;
        }
    }
}

button.cancelButton {
    color: $red;
    fill: $red;
    border-color: $red;
} 

.frame {
    border: 0;
    width: 100%;
    height: 176px;
}

.submissionDetailsContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: visible;
}

.submissionDetailsContent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 16px;
}
