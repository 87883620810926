.noMessage {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: hidden !important;
  color: #ccc;

  h1 {
    font-size: 2rem;
    text-align: center;
    color: #ccc;
  }

  svg {
    width: 48px;
    height: 48px;
    opacity: 0.666;
  }
}
