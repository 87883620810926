#file-uploader {
  width: 100% !important;
  height: auto !important;
  background-color: #eaeaea;
  border-style: none !important;
  cursor: pointer;

  p {
    padding: 15px;
  }
}

#file-uploader button {
  margin: {
    top: 10px !important;
  }
}

.fileName {
  padding: {
    top: 5px;
    bottom: 5px;
  }
}

.progressInfo {
  .progressBar {
    height: 10px;
    border-radius: 5px;
  }

  .progressText {
    display: flex;
    justify-content: space-between;
  }
}

.successMessage {
  margin-bottom: 10px;
  background-color: #37bd5d;
  width: 250px;
  padding: 10px;
  border-radius: 5px;
}


.progressBar {
  height: 10px;
  border-radius: 5px;
}