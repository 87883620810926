.floatingPanel {
    position: fixed;
    right: 10px;
    bottom: 24px;

    height: 54px;


    width: 800px;
    max-width: 100%;
    padding: 0 12px;

    background-color: #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 4px;

    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);

    .panelLeft {
        flex-grow: 1;
    }

    button,
    a {
        margin-top: 0;
        
        & + button,
        & + a {
            margin-left: 8px;
        }
    }

    &.embed {
        padding-left: 90px;
        border-radius: 0;
        box-shadow: 0 0 0 0 transparent;
        bottom: 0;
        right: 0;
        width: 100%;
        transition: padding-left 0.2s ease;
        border-top: 1px solid rgba(0, 0, 0, 0.12);

        .activityBar:hover ~ main &,
        .activityBar.locked ~ main & {
            padding-left: 266px; 
        }
    }
}

.floatingPanelSpacer {
    height: 80px;

    .embed + & {
        height: 54px;
    }
}
