@import '../abstracts';

$calculatorBgColor: $priceCalculatorBgColor;
$calculatorColor: $priceCalculatorColor;

.calculatorFormGrid {
  display: flex;
  flex-direction: column;
}

.calculatorForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  background-color: $calculatorBgColor;
  padding: 16px;
  border-radius: 6px;
  align-self: stretch;

  h5 {
    color: $calculatorColor;
    font-weight: bold;
  }

  .calculatorSelect {
    div, svg, p {
      color: $calculatorColor;
    }

    & > div:after,
    & > div:before {
      border-color: $calculatorColor !important;
    }
  }

  button {
    background-color: $calculatorColor !important;
    color: $calculatorBgColor !important;

    &:hover {
      background-color: darken($calculatorColor, 5%) !important;
    }

    &[disabled] {
      background-color: rgba($calculatorColor, 0.45) !important;
      color: rgba($calculatorBgColor, 0.45) !important;
    }
  }

  #runtimeSlider,
  #runtimeSliderV2,
  #locationsSlider {
    & > div > div {
      background-color: $calculatorColor ;
    }

    button {
      box-shadow: $calculatorBgColor;

      box-shadow: 0px 0px 0px 0px rgba($calculatorColor, 0.16);

      &:hover,
      &:focus,
      &:active {
        box-shadow: 0px 0px 0px 9px rgba($calculatorColor, 0.125);
      }
    }
  }

  label {
    color: $calculatorColor !important;
    font-size: 18.5px;

    &[for="runtimeSlider"], &[for="runtimeSliderV2"], &[for="locationsSlider"] {
      font-size: 14px;
    }
  }

  .sliderValue {
    fieldset {
      border-color: $calculatorColor !important;
    }

    input {
      color: $calculatorColor;
    }
  }
}

#runtimeSlider {
  & > div > div {
    background-color: $calculatorBgColor;
  }
}

.sliderValue {
  input {
    padding: 8px;
  }
}

.calculatorProgress {
  svg {
    color: $calculatorBgColor;
  }
}

.priceTable {
  margin: 0;

  table {
    position: relative;
    z-index: 10;
    border-collapse: separate;
  }

  .explanation {
    position: relative;
    z-index: 20;
    margin-bottom: 2px;
    text-align: right;
    padding: 0 6px;
    color: $priceCalculatorBgColor;
  }

  td,
  th {
    font-size: 20px;
    text-align: center;
    padding: 12px 20px;
    text-shadow: 0 0 5px rgba(black, 0.185);

    &:nth-child(1) {
      border-top: none;
      text-align: left;
      max-width: 45%;
    }

    &:nth-child(even) {
      background-color: $calculatorBgColor !important;
      border-color: $calculatorBgColor !important;
      color: $calculatorColor !important;
    }

    &:nth-child(2) {
      border-top-left-radius: 6px;
    }

    &:last-child {
      border-top-right-radius: 6px;
    }
  }

  td {
    font-size: 18px;
    border-radius: 0 0 0 0 !important;
  }

  tr {
    font-size: 18px;
    border-radius: 0 0 0 0 !important;
    &:last-child {
      padding: 0px;
      height: 6px;
    }

    &:last-child td {
      &:nth-child(1) {
        border-bottom: none;
      }
    }

    &:nth-child(odd) td {
      background-color: rgba(#efefef, 0.33);

      &:nth-child(even) {
        background-color: lighten($calculatorBgColor, 5%) !important;
        border-color: lighten($calculatorBgColor, 5%) !important;
        color: $calculatorColor !important;
        min-width: 110px;
      }
    }
  }
}
