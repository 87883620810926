@import '../../abstracts';

.dataTable {
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  &.fullScreen {
    position: fixed;
    left: 8px;
    right: 8px;
    top: 8px;
    bottom: 62px;
    z-index: 3500;
    border-radius: 4px 4px 0 0;
    width: auto;
    overflow: hidden;
    margin: 0;
    padding-bottom: 56px;

    & + .sheet {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 3400;
      overflow-y: auto;
      background-color: rgba(black, 0.65);
    }

    .hScroll {
      max-width: 100%;
      overflow: auto;
      height: 100%;
    }

    .pagination {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: white;
    }

    & ~ div > .floatingPanel.embed,
    & ~ .floatingPanel.embed {
      position: fixed;
      bottom: 8px;
      left: 8px;
      right: 8px;
      z-index: 3500;
      width: auto;
      border-radius: 0 0 4px 4px;
    }

    bottom: 8px;
    border-radius: 4px;
  }

  .fullScreenControl {
    position: absolute;
    left: 2px;
    bottom: 2px;
    z-index: 5;
  }

  .filterText {
    input {
      font: {
        size: 1rem;
        weight: normal;
      }
      position: relative;
      bottom: 2px;
    }

    label {
      font: {
        size: 1rem;
        weight: normal;
      }
    }
  }
  
  .filterSelect {
    em {
      font: {
        size: 1rem;
        weight: normal;
      }
    }

    div > div {
      font: {
        size: 1rem;
        weight: normal;
      }
      position: relative;
      bottom: 2px;
    }

    svg {
      margin-top: -3px;
    }
  }

  a {
    color: $blueDark;

    &:hover {
      color: $blue;
    }
  }

  th {
    vertical-align: bottom;
    
    span {
      display: flex;
      align-items: center;

      & + div {
        width: 100%;
      }
    }

    p {
      display: inline;
      white-space: nowrap;
      font-size: 0.955rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  td {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th,
  td {
    min-width: 115px;
    max-width: 115px;

    &.cell-small,
    &.cell-rating {
      min-width: 72px;
      max-width: 72px;
    }

    &.cell-id {
      min-width: 63px;
      max-width: 63px;
    }

    &.cell-email {
      min-width: 127px;
      max-width: 200px;
    }

    &.cell-companyName {
      min-width: 120px;
      max-width: 180px;
    }

    &.cell-updatedAt,
    &.cell-createdAt,
    &.cell-lastLogin {
      min-width: 120px;
      max-width: 120px;
    }

    // &.cell-options {
    //   overflow: visible;
    //   min-width: 53px;
    //   max-width: 53px;
    //   width: 53px;
    // }
  }

  th.cell-options {
    p, span {
      display: none;
    }
  }
}

body.dataTableFullscreen .content {
  z-index: 3500;
}
