@import '../../abstracts';

div.downloadCertificate,
div.certificateCard {
    $downloadColor: $teal;



    align-items: center;
    background-color: $downloadColor;
    min-height: 44px;

    & + & {
        margin-top: -10px;
    }

    &.downloadPreviewCertificate {
        background-color: $grey;
    }

    .certificateCardLeftCol {
        display: flex;
        flex-direction: column;

        & > p {
            margin-top: 8px;
            color: $white;
            font-size: 1rem;
        }
    }
    
    .certificateCardLeftColInner {
        display: flex;
        flex-direction: row;
    }

    .certificateCartMessage {
        p, a {
            color: $white !important;
        }

        a {
            text-decoration: underline;
        }

        p {
            font-size: 0.925rem;
            margin: {
                top: 8px;
            }
        }
    }

    .logo {
        display: block;
        margin-right: 16px;
        height: 44px;
        min-height: 44px;
        width: 101px;
        min-width: 101px;
        background-position: center left;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-image: url(/assets/images/logos/bbfc-logo-l-w.png);
        transition: left 0.2s ease;
    }

    .rating-icon {
        margin-top: 3px; 
        min-width: 40px;
    }

    .inputs {
        display: flex;
        flex-direction: column;

        & + button {
            margin-left: 8px;
        }
    }

    .actions {
        margin-left: auto;
        flex-direction: column;
        display: flex;
        align-items: flex-end;
        align-self: stretch;
        flex-wrap: wrap;
        justify-content: space-between;

        div[role="progressbar"] {
            margin-right: 16px;

            svg {
                fill: $white;
                color: $white;
            }
        }

        .actionDownloadButton {
            display: flex;
        } 

        .actionButtons {
            margin-top: auto;
            display: flex;
        } 
    }

    

    h6 {
        font-size: 1.1rem;
        line-height: 1.1rem;
        margin-bottom: 16px;
        text-align: right;
        color: $white;
        width: 100%;
        white-space: nowrap;
    }

    button {
        background-color: white;
        color: $downloadColor;
        margin-top: 6px;
    
        &:hover {
          background-color: darken(white, 5%);
        }

        &.declineButton {
            background-color: white;
            color: $red;
        
            &:hover {
                background-color: darken(white, 5%);
            }
        }

        & + button {
            margin-left: 8px;
        }
    }

    &.downloadPreviewCertificate button {
        color: $grey;
    }

    .classificationInput {
        min-width: 300px;

        div, svg, p {
          color: white;
        }
    
        & > div:after,
        & > div:before {
          border-color: white !important;
        }

        input::placeholder,
        textarea::placeholder {
            color: $white;
            opacity: 1;
        }

        &.text {
            & > div {
                padding: 8px;
            }

            fieldset {
                border-color: white !important;
            }
        }
    }

    .downloadFile {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & + .downloadFile {
            border-top: 1px solid $white;
        }

        span {
            svg {
                margin-bottom: 1px;
            }
        }

        & > span {
            display: flex;
            align-items: center;
            color: $white;
            font-size: 1.1rem;
        }

        .fileName {
            flex-grow: 1;
            min-width: 0;
            font-weight: 600;

            & > span {
                display: inline-block;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .fileSize {
            flex-shrink: 0;
        }

        button {
            flex-shrink: 0;
            margin-top: 0;
            padding: 6px 10px;
            height: 28px;
            line-height: 16px;
        }
    }

    .rejectClassification {
        color: white;
        font: {
            weight: 500;
            size: 1.045rem;
        }
        text-align: right;

        a {
            color: white;
            display: inline-flex;
            align-items: center;
        }
    }
}

div.downloadCertificate {
    display: flex;
    justify-content: space-between;
}


div.certificateMessage {
    $downloadColor: $teal;

    align-items: center;
    background-color: $downloadColor;
    min-height: 44px;
    color: white;
}