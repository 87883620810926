@import '../../abstracts';

.messageContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: {
    top: -6px;
    left: -16px;
    right: -16px;
  }
  background-color: transparent;
}

.messageGrid {
  width: 100% !important;
}

.messages {
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 20px;
  margin-bottom: -10px;

  & > div:first-child {
    flex-grow: 1;
    padding-top: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 0;
  }
}
