.breadcrumbs {
   display: flex; 
   align-items: center;
   margin: -20px -10px 0 -20px;
   border-bottom: 1px solid rgba(0, 0, 0, 0.05);
   padding: {
       top: 10px;
       bottom: 8px;
       left: 22px;
       right: 22px;
   };
   height: 40px;
   background-color: rgba(0, 0, 0, 0.045);
   opacity: 0.5;

   p {
       font-size: 0.8rem;
       max-width: 200px;
       white-space: nowrap;
       overflow: hidden;
       text-overflow: ellipsis;
   }
}
