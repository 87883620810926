@import '../../variables';

.longRatingsInfoTopLevelTag {
  border: 1px solid rgba(224, 224, 224, 1);

    button,
    a {
        width: auto;

        & + button,
        & + a {
            margin-left: 8px;
        }
    }
}

.longRatingsInfoEditor {
    padding: 10px;
}

.longRatingsInfoEditorItem {
    padding: 15px;

    .title {
        margin-bottom: 10px;
    }

    .description {
        min-height: 85px;
        margin-bottom: 10px;
    }

    .actions {
        padding-bottom: 10px;
    }

    .editIcon {
        color: $grey20 !important;
        cursor: pointer;
    }

    .removeIcon {
        color: $black !important;
        cursor: pointer;
    }
}