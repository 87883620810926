#videoGrid {
  width: calc(100% + 22px);

  .videoItem {
    width: 200px;
    display: flex;

    button {
      height: 100%;
      flex-direction: column;
    }
  }
}

.loadMore {
  button {
    max-width: inherit;
  }
}
