@import "../../variables";

.topLevelTagEditor {
  
  .tag-selector {
    padding-left: 21px;
    padding-bottom: 21px;
    overflow-y: unset;
  }

  .description-input {
    width: 550px;
    background-color: $grey95;
    border: none !important;
  }
}