.statusChipList {
  white-space: normal;

  .statusChipListInner {
    margin: {
      right: -8px;
      bottom: -8px;
    };
  }
  
  .statusChip {
    margin: {
      top: 0px;
      right: 8px;
      bottom: 8px;
      left: 0px;
    };
  }
}
