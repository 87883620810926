.qr {
    position: relative;
    width: 100%;
    max-width: 320px;
    margin: {
        left: auto;
        right: auto;
    }
    border: {
        width: 4px;
        style: solid;
        color: rgba(0, 0, 0, 0.165);
        radius: 4px;
        padding: 4px;
    }

    svg {
        display: block;
        width: 100%;
        height: auto;
    }
}

div.authError {
    min-width: 0;
}
