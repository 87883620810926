.task-details {
  display: flex;
  flex-direction: column;
  max-height: 90vh;

  .subtitle p {
    font-weight: bold;
    font-size: 0.975rem;
    margin: 0;
  }

  .vScroll {
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: auto;
  }

  .actions {
    min-height: 46px;
  }
}