@import '../../abstracts';

div.statusChip {
    $status-neutral: $grey;
    $status-safe: $green;
    $status-alert: $yellow;
    $status-danger: $red;

    position: relative;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    border-radius: 6px;
    background-color: $status-neutral;
    display: inline-flex;

    &.removed {
        text-decoration: line-through;
        text-decoration-color: $white;
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;

        background: linear-gradient(135deg,rgba(0,0,0,.15),transparent,hsla(0,0%,100%,.1));
        background-blend-mode: overlay;
        background-color: inherit;
    }

    & > span {
        position: relative;
        z-index: 10;
        display: block;
        box-sizing: border-box;
        padding: {
            left: 7px;
            right: 7px;
        }
        max-width: 100%;
        color: $white;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font: {
            size: 14px;
            weight: normal;
        }
    }

    // Size variants
    &.big {
        line-height: 26px;

        & > span {
            padding: {
                left: 11px;
                right: 11px;
            }
            font-size: 16.5px;
        }
    }

    // Safe color
    &.status-SUCCEEDED,
    &.status-ALLOCATION_CONFIRMED,
    &.status-COMPLETED,
    &.status-OK {
        background-color: $status-safe;
    }

    // Alert color
    &.status-ALLOCATION_CONFIRMATION,
    &.status-COMPLIANCE_REVIEW,
    &.status-WAITING_FOR_VIDEO,
    &.status-WAITING_FOR_ALLOCATION,
    &.status-WAITING_FOR_VPRC_PACKAGING,
    &.status-HOLD,
    &.status-WAITING_FOR_SOMETHING,
    &.status-RESUBMISSION,
    &.status-AWAITING_PAYMENT {
        background-color: $status-alert;
    }

    // Danger color
    &.status-overdue,
    &.status-NEW,
    &.status-VIDEO_PROVIDING_EXPIRED,
    &.status-CANCELLED,
    &.status-ALLOCATION_REFUSED,
    &.status-COMPLIANCE_REVIEW_CONFIRMATION,
    &.status-BLOCKED,
    &.status-GOING_TO_EXPIRE,
    &.status-DELETED,
    &.status-INACTIVE,
    &.status-INVOICING_FAILED {
        background-color: $status-danger;
    }

    &.faded {
        opacity: 0.4;
    }
}

.cutsStatus {
    & > span {
        font: {
            size: 13px !important;
        }
    }
}
