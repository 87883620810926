@import '../../abstracts';

#notifications {
  $borderColor: #d5d5d5;

  & > div + div { 
    margin-left: -6px;
  }

  ul {
    padding: 0;
  }

  li.notification {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    transition: color 0.05s linear;
    border-left: 6px solid rgba(0, 0, 0, 0.11);
    white-space: normal;

    & + & {
      border-top: 1px solid rgba(0, 0, 0, 0.11);
    }

    &.RED {
      border-left-color: $red;
    }

    &.AMBER {
      border-left-color: $yellow;
    }

    &.GREEN {
      border-left-color: $green;
    }

    &.viewed {
      color: rgba(0, 0, 0, 0.5);
      border-left-color: rgba(0, 0, 0, 0.055);

      &:hover {
        color: inherit;
      }

      &.RED {
        border-left-color: rgba($red, 0.5);
      }
    
      &.AMBER {
        border-left-color: rgba($yellow, 0.5);
      }
    
      &.GREEN {
        border-left-color: rgba($green, 0.5);
      }
    }

    .notificationHeader {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    div {
      display: flex;
      align-items: center;
    }

    svg {
      font-size: 16px;
      opacity: 0.65;
    }

    b {
      max-width: 250px;
    }

    i {
      margin-left: auto;
      font-size: 0.85em;
    }
  }

  .notificalonControls {
    border-bottom: 1px solid $borderColor;
    display: flex;

    & > a {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}