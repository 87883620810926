@import "../abstracts";

$borderColor: #d5d5d5;

$status-neutral: $grey;
$status-safe: $green;
$status-alert: $yellow;
$status-danger: $red;

.bt {
  border-top: 1px solid $borderColor !important;
}

.bb {
  border-bottom: 1px solid $borderColor !important;
}

.bl {
  border-left: 1px solid $borderColor !important;
}

.br {
  border-right: 1px solid $borderColor !important;
}

.dashboardBackground {
  position: fixed;
  left: 0;
  right: 0;
  top: 64px;
  bottom: 0;

  z-index: -1000;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: none;

  background: {
    image: url(/assets/images/backgrounds/horizon.jpg);
    repeat: no-repeat;
    position: center;
    size: cover;
    color: $black;
  }
}

.myDashboardContent {
  .myDashboardContentTitle {
    font: {
      size: 2.75rem;
      weight: 500;
    }
    text-shadow: 0 0 20px rgba($black, 0.45);
    color: $white;

    @include breakpoint-down($md) {
      font-size: 2.5rem;
    }

    @include breakpoint-down($sm) {
      font-size: 2.25rem;
      color: $white;
      text-shadow: 0 0 20px rgba($black, 0.45);
    }
  }
}

.dashboardTite {
  span span {
    top: 6px;
  }
}

.dashboardAccordion {
  .dashboardAccordionSummary {
    padding: {
      left: 18px;
      right: 18px;
    }
  }
}

.infoCard {
  display: flex;

  h6 {
    font-size: 1.15rem;
  }
}

.infoCardIndicator {
  $status-neutral: $grey;
  $status-safe: $green;
  $status-alert: $orange;
  $status-danger: $red;

  display: flex;
  position: relative;
  width: 64px;
  background-color: $status-neutral;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;

    background: linear-gradient(
      135deg,
      rgba(0, 0, 0, 0.2),
      transparent,
      hsla(0, 0%, 100%, 0.15)
    );
    background-blend-mode: overlay;
    background-color: inherit;
  }

  &.status-danger {
    background-color: $status-danger;
  }

  &.status-alert {
    background-color: $status-alert;
  }

  &.status-ok {
    background-color: $status-safe;
  }

  svg {
    position: relative;
    z-index: 5;
    margin: auto;
    fill: white;
    width: 26px;
    height: 26px;
  }
}

.dashboardClientOrders {
  width: 100%;

  & .headerLink:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.dashboardClientOrdersWorkOrder {
  margin: {
    top: -1px;
    bottom: -1px;
  }
}

.dashboardClientOrderItem {
  display: flex;
  flex-direction: column;
  border-right: 1px solid $borderColor;

  @include breakpoint-down($md) {
    border-right: 0;
  }

  @include breakpoint-btw($md, $lg) {
    &:nth-child(2n) {
      border-right: 0;
    }
  }

  @include breakpoint-up($lg) {
    &:nth-child(3n) {
      border-right: 0;
    }
  }
}

.dashboardClientOrderHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: $teal;
  height: auto;

  h6 {
    font-size: 1.2rem;
  }
}

.dashboardClientOrdersWorkOrderHeader {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px dashed #d5d5d5;

  h6 {
    font-size: 0.925rem;
  }

  p {
    margin: 0;
  }
}

.dashboardClientOrderContent {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;

  & > .actions {
    display: flex;
    justify-content: space-between;
    // background-color: rgba($borderColor, 0.2);

    @include breakpoint-down($md) {
      justify-content: flex-start;

      & > * {
        margin-right: 1em;
      }
    }

    button {
      background-color: $white;
    }
  }
}

.dashboardClientOrdersWorkOrderContent {
  & .details {
    display: flex;

    & :first-child {
      font-weight: bold;
      margin-right: 0.25em;
    }
  }

  & .actions {
    display: flex;
    flex-direction: column;
  }
}
