@import "../abstracts";

.activityBar {
  & > div {
    display: flex;
    z-index: 1200;
    border-right: 0;
    width: 65px;
    transition: width 0.2s ease;
    overflow: visible;
    box-shadow: inset -1px 0 0 0 rgba(black, 0.0654);
    background-color: #3a4850;
    margin-top: 64px;

    @include breakpoint-down($sm) {
      margin-top: 56px;
    }

    .lockButton {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #d5d9dc;
      height: 36px;
      transition: color 0.2s linear 0.05s;
      padding: 6px 20px;
      cursor: pointer;
    }
  }

  &.locked {
    & > div {
      width: 200px;
    }
  }
}
