@import '../../../abstracts';

.rowDetail {
  .company {
    color: $black;
    text-decoration: none;

    svg {
      margin-bottom: -2px;
    }
  }
}