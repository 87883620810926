.autosuggestContainer {
  position: relative;

  & > div > div {
    flex-direction: row;
    flex-wrap: wrap;
    margin: {
      top: 22px;
      left: -3px;
      right: -3px;
    }
  }

  div[role="button"] {
    height: 28px;
    font-weight: bold;

    margin: 3px;

    span {
      padding: {
        left: 7px;
        right: 9px;
      }
    }

    svg {
      margin-right: 2px;
    }
  }

  input {
    margin: {
      left: 3px;
      right: 3px;
    }
    width: 100%;

  }
}

.autosuggestMenu {
  position: absolute;
  z-index: 1000;
  left: 0;
  right: 0;
}
