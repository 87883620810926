@import "../../abstracts";

.modal.imageCropperModal {
    @extend %centerFixed;

    width: 400px;
    max-width: 100%;
    transform: translate(-50%, -50%);
    padding: {
        top: 12px;
        left: 12px;
        right: 12px;
    }
    outline: none;

    .actions {
        position: relative !important;
        bottom: 0px;
        padding: {
            top: 4px;
            bottom: 12px;
        }
        display: flex;
        justify-content: space-between;
        background-color: $white;

        button + button {
            margin-left: 12px;
        }
    }
}