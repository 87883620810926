.rating-icon-12,
.rating-icon-12a,
.rating-icon-12A,
.rating-icon-15,
.rating-icon-18,
.rating-icon-PG,
.rating-icon-R18,
.rating-icon-U,
.rating-icon-Uc {
  display: block;
  width: 40px;
  height: 40px;
  color: transparent !important;
  font-size: 0;
  background: {
    position: center;
    size: contain;
    repeat: no-repeat;
  }
}

.rating-icon-inline {
  display: inline-block;
  margin: {
    left: 6px;
    right: 6px;
  }
}

.rating-icon-small {
  width: 32px;
  height: 32px;
}

.rating-icon-big {
  width: 80px;
  height: 80px;
}

.rating-icon-huge {
  width: 128px;
  height: 128px;
}

.rating-icon-12 {
  background-image: url(/assets/images/ratings/12.png);
}

.rating-icon-12a,
.rating-icon-12A {
  background-image: url(/assets/images/ratings/12a.png);
}

.rating-icon-15 {
  background-image: url(/assets/images/ratings/15.png);
}

.rating-icon-18 {
  background-image: url(/assets/images/ratings/18.png);
}

.rating-icon-PG {
  background-image: url(/assets/images/ratings/pg.png);
}

.rating-icon-R18 {
  background-image: url(/assets/images/ratings/r18.png);
}

.rating-icon-U {
  background-image: url(/assets/images/ratings/u.png);
}

.rating-icon-Uc {
  background-image: url(/assets/images/ratings/uc.png);
}
