@import '../../abstracts';

.deleted {
  text-decoration: line-through;
}

.cardDetailAccordion {
  position: relative;
  z-index: 60;
  margin: {
    top: 24px !important;
    bottom: -24px !important;
    left: -16px !important;
    right: -16px !important;
  };
  min-width: calc( 100% + 32px );

  &:before {
    opacity: 1 !important;
  }

  .summary {
    justify-content: center;
    min-height: 38px !important;
    
    & > div:first-child {
      display: none;
    }

    & > div:last-child {
      left: auto !important;
      right: auto !important;
      margin: 0 !important;
      padding: 6px !important;
    }

    /*&:after {
      content: "";
      bottom: -1px;
      left: 0;
      right: 0;
      height: 1px;
      opacity: 1;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.12);
    }*/
  }
  
  .details {
    overflow: hidden;
    padding: {
      left: 16px !important;
      right: 16px !important;
      bottom: 16px !important;
      top: 8px !important;
    }

    div {
      min-width: 100%;
    }
  }

  .workOrder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: {
      top: 3px;
      bottom: 3px;
      left: 8px;
      right: 8px;
    }
    width: 100%;
    min-height: 30px;
    text-align: left;
    //background: transparent;
    background-color: rgba($black, 0.035);
    transition: background-color 0.25s ease;
    border: none;
    border-radius: 0;

    &:hover {
      background-color: rgba($black, 0.065);
    }

    &:first-child {
      border-radius: 4px 4px 0 0;
    }

    &:last-child {
      border-radius: 0 0 4px 4px;
    }

    &:first-child:last-child {
      border-radius: 4px;
    }
  } 
}
