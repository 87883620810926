/* Variables */

// Colors
// Brand Guideline > page 33
$black: #000000;
$white: #ffffff;
$grey: #374a52;
$teal: #00ada7;
$green: #0ac700;
$yellow: #fbad00;
$orange: #ff7d13;
$pink: #fb4f93;
$red: #dc0a0a;
$blue: #006ed2;
$jade: #005500;
$rose: #981818;

// bbfc-theme colors
$bbfcGreen: #08918c;
$bbfcLightGreen: #edf7f7;
$bbfcBlue: #3e7cf4;
$bbfcDarkBlue: #083791;
$grey95: #F5F6FA;
$grey85: #D5D9DC;
$grey70: #ACB2B9;
$grey60: #9098A2;
$grey50: #747F8B;
$grey20: #304148;
$grey10: #274348;
$darkGrey: #3A4850;

// Dark color fallbacks
$blueDark: darken($blue, 12.5%);
$greenDark: darken($green, 12.5%);
$redDark: darken($red, 12.5%);
$yellowDark: darken($yellow, 12.5%);
$tealDark: darken(teal, 12.5%);
$greyLight: lighten($grey, 12.5%);
$greyDark: darken($grey, 12.5%);

$borderColor: #d5d5d5;

// Responsive breakpoints
$xs: 0px;
$extra-small: $xs;
$sm: 600px;
$small: $sm;
$md: 960px;
$medium: $md;
$lg: 1280px;
$large: $lg;
$xl: 1920px;
$extra-large: $xl;

// Add form settings
$addFormBgColor: $grey;
$addFormColor: $white;

$priceCalculatorBgColor: $grey;
$priceCalculatorColor: $white;
