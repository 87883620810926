@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

.editor-component {
  display: flex;
  justify-content: space-between;
  margin: 5px;

  .editor-wrapper {
    width: 75%;

    .editor-class {
      border: 1px solid #F1F1F1;
      padding: 5px;
    }

    .toolbar-class {
      background: #faf7f7 !important;
    }

    .rdw-link-modal {
      top: -75px !important;
    }
  }
}

.edit-mode-wrapper {
  display: flex;
}