@import '../../abstracts';

div.messageFormGrid {
  position: relative;
  z-index: 5;
  margin-top: 8px;
  min-height: 44px;

  &.open {
    min-height: 224px;
  }
}

.sendMessage {
  display: flex;
  flex-direction: column;
  //position:sticky;
  bottom: 0;
  top: 0;
  z-index: 500;
  padding: {
    top: 0;
    right: 60px;
    bottom: 8px;
    left: 60px;
  }
  background-color: $white;
  
  @include breakpoint-down($md) {
    padding: {
      right: 91px;
      left: 80px;
    }
  }
  
  button.sendMessage {
    align-self: flex-end;
    margin: {
      top: 4px;
      bottom: 4px;
      left: 5px;
    }
  }

  button.cancelMessage {
    align-self: flex-end;
    margin: {
      top: 4px;
      bottom: 4px;
      left: auto;
    }
  }

  .sendMessageControls {
    margin-top: 16px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    @include breakpoint-down(1000px) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  .attachFiles {
    display: flex;
    flex-direction: column;
    margin: {
      top: 4px;
      bottom: 4px;
    }
  }

  .fileUploadInput {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 8px;
    width: 270px;
    max-width: 270px;

    input {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
    }

    .fileUploadInputLabel {
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.23);
      margin-right: 8px;
      padding: 4px 6px;
      color: $black;
      text-decoration: none;
      border-radius: 4px;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 0.75rem;
      white-space: nowrap;
    }

    .fileUploadInputFileName {
      font-size: 0.75rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-shrink: 1;
      min-width: 0;
    }
  }

  .attachFile {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding-right: 16px;

    & + .attachFile {
      margin-top: 8px;
    }

    .attachFileActions {
      display: flex;
      flex-wrap: nowrap;
      width: 176px;

      @include breakpoint-down(805px) {
        width: auto;
      }
    }

    .addNew,
    .remove {
      display: flex;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.23);
      padding: 4px 6px;
      color: $black;
      text-decoration: none;
      border-radius: 4px;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 0.75rem;
      white-space: nowrap;

      svg {
        margin-left: -2px;
      }

      & + a {
        margin-left: 6px;
      }
    }

    .remove {
      color: #dc0a0a;
      border-color: #dc0a0a;
      fill: #dc0a0a;
    }

    @include breakpoint-down(805px) {
      svg {
        margin: 0 !important;
      }

      a span {
        display: none;
      }
    }
  }

  .rdw-editor-toolbar {
    margin-bottom: 0;
    border: {
      top: none;
      right: none;
      bottom: 1px solid rgba(0, 0, 0, 0.23);
      left: none;
      radius: 6px 6px 0 0;
    }
  }

  .message-textbox-editor {
    max-height: 200px;
    overflow-y: auto;
  
    h1, h2, h3, h4, h5, h6 {
      margin: {
        top: 1em;
        bottom: 1em;
      }
    }

    h1 {
      font-size: 1.4rem;
    }

    h2 {
      font-size: 1.25rem;
    }

    h3 {
      font-size: 1.15rem;
    }

    h4 {
      font-size: 1rem;
    }

    p, h5, h6 {
      font-size: 0.9rem;
  
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  
  .message-textbox-toolbar {
    .rdw-option-wrapper,
    .rdw-dropdown-wrapper {
      margin: {
        left: 2px;
        right: 2px;
      }
      box-shadow: 0 0 0 0 transparent !important;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: rgba(0, 0, 0, 0.08);
      }

      &:active {
        background-color: rgba(0, 0, 0, 0.225);
      }
    }

    .rdw-option-wrapper {
      min-width: 23px;
      border-color: rgba(0, 0, 0, 0.23);
    }

    .rdw-dropdown-wrapper {
      border-color: rgba(0, 0, 0, 0.23);
    }
  }

  li .public-DraftStyleDefault-block {
    margin: {
      top: 0;
      bottom: 0;
    }
  }
}

.messagePillow {
  margin-top: -36px;
}

.message-textbox-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

.message-textbox-editor {
  padding: 18.5px 14px;
}

.editor-error {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #dc0a0a;
  padding: {
    top: 4px;
    bottom: 4px;
  }
}
