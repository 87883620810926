@import '../../abstracts';

.cutList {


  .cutItem {
    display: flex;
    border-radius: 6px;
    border: 1px solid $borderColor;
    //background-color: $grey;
    //color: $white;

    & + .cutItem {
      margin-top: 16px;
    }

    .cutItemIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid $borderColor;
      border-radius: 6px 0 0 6px;
      padding: 16px;

      background-color: $grey;
      color: $white;
      fill: $white;

      svg {
        margin: 0;
        width: 36px;
        height: 36px;
        transform: rotate(0.5turn);
      }
    }

    .highlighted {
      background-color: $rose;
    }

    &.confirmed .cutItemIcon {
      background-color: $teal;
    }

    .cutItemContent {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 16px;

      .subtitle {
        white-space: pre-wrap;
      }
    }
  }
}

.resubmissionTask {
  .formImputRow {
    min-height: 80px;
  }

  .checkboxLabel {
    margin-right: 0;
  }

  .checkboxErrorLabel {
    margin-top: 0;
    margin-left: 42px;
  }
}