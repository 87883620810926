@import '../abstracts';

.loggedUserMenu,
.menuUser {
    button {
        background-color: white;
        color: $grey;

        &.hidden {
            visibility: hidden;
        }

        &:hover {
            background-color: white !important;
        }
    }

    .menuAvatar {
        margin-right: 8px;
        width: 26px;
        height: 26px;
        line-height: 27px;
        background-color: $teal;
        color: white;
        font-size: 0.85em;
    }

    .menuAvatar,
    .company {
        @include breakpoint-down(800px) {
            display: none;
        }
    }

    .menuIcon {
        margin-left: 8px;
        width: 18px;
    }
}

.menuUser {
    display: flex;
    align-items: center;
    padding: 6px 8px;
    color: $teal;
    font: {
        size: 14px;
        weight: 500;
    }
    text-transform: uppercase;
    cursor: pointer;

    .menuAvatar {
        background-color: $teal;
        color: white;
        display: flex !important;
    }

    .company {
        display: inline;
    }

    & + .companyLogo {
        margin: auto;
        width: 100%;
        max-width: 176px;
        height: 82px;
        background: {
            position: center;
            repeat: no-repeat;
            size: contain;
        }
    }
}

.userMenuActions {
    background-color: rgba(#eeeeee, 0.65);
}

.userRoles {
    color: #2e2e2e;
    padding: 12px 6px;
    margin: 6px 8px;
    border-top: 1px solid rgba(#6c6c6c, 0.25);

    span {
        display: block;
        font-size: 18px;
        margin-bottom: 6px;
    }

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none; 
    }

    li {
        color: inherit;
        font-size: 15px;

        &:before {
            content: '';
            display: inline-block;
            width: 6px;
            height: 6px;
            margin: {
                right: 6px;
                bottom: 2px;
            }
            background-color: #6c6c6c;
            border-radius: 50%;
        }

        & + li {
            margin-top: 4px;
        }
    }
}

.userAdmin {
    margin-left: 8px;
}
