@import "../../abstracts";

#videoDetails {
  display: flex;

  video {
    max-width: 45%;
  }

  .poster {
    min-width: 320px;
    max-height: 750px;
  }

  .mainContent {
    flex-grow: 1;

    .title {
      display: flex;

      h1 {
        color: $teal;
        flex-grow: 1;
      }
    }

    .content {
      padding: 16px;

      .type {
        margin-bottom: 20px;
      }
    }

    .details {
      height: 100%;
      background-color: $grey;
      color: $white;

      .notes {
        padding-top: 10px;

        h6 {
          font-weight: inherit;
        }
      }

      p {
        display: inline;
        padding-right: 20px;
      }
    }
  }
}

img.advice {
  filter: grayscale(100%);
}
