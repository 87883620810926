@import '../../abstracts';

.loginForm {
    width: 100%;

    .passwordResetLink {
        font-size: 0.9rem;
        color: $blue;
    }
}

button.loginButton,
a.loginButton {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.23);

    color: rgba(0, 0, 0, 0.87);

    &:hover {
        background-color: rgba(0, 0, 0, 0.021);

    }

    &.googleLogin {
        font-family: Roboto, sans-serif;
        transition:
            border-color 0.2s ease, 
            background-color 0.2s ease
        ;

        span {
            text-transform: none;
            color: #767676;
            transition:
                color 0.2s ease, 
                background-color 0.2s ease
            ;
        }    

        svg path:not([fill="none"]) {
            transition: fill 0.2s ease;
        }

        &:hover {
            background-color: #5075ED;
            border-color:#5075ED;
    
            span {
                color: white;
            }
    
            svg path:not([fill="none"]) {
                fill: white;
            }
        }
    }
}