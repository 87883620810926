@import '../../abstracts';

div.addCard {
    margin-bottom: 24px;
    padding: 16px;
    overflow: visible;
}

div.addHeader {
    display: none;
}

div.workOrderMeta {
    & > div {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding-bottom: 16px !important;
    }

    &,
    & h6 {
        color: $black !important;
    }

    .workOrderMetaTitle {
        margin: {
          top: -16px;
          right: -16px;
          left: -16px;
        }
        padding: {
            top: 8px;
            bottom: 8px;
            left: 16px;
            right: 16px;
        }
        background-color: $orange;
        color: $white !important;
    
      }
}

div.workOrderMetaData {
    span {
        font-weight: bold;
    }

    & + & {
        margin-top: 8px;
    }
}

div.workOrderMetaDate {
    display: flex;
    align-items: center;

    svg {
        margin-left: 8px;
    }

    & + & {
        margin-top: 8px;
    }
}

.workOrderMetaPrice,
.workOrderMetaPriceInfo {
    text-align: right;
}

button.acceptButton {
    background-color: $greenDark;
    color: white;

    &:hover {
        background-color: darken($greenDark, 5%);
    }
}

.progressBarContainer {
    background-color: $darkGrey;
    margin: -18px -10px 0px;
    height: 178px;
}

.formContainer {
    margin-top: -65px;
    width: 100%;
}
