@import "../abstracts";
@import "../utilities";
@import "../typo";
@import "../components/ratingIcons";
@import "../components/switch";
@import "../components/iconBadge";
@import "./activity-bar";
@import "./content-placeholder";
@import "./environment-indicator";
@import "./logged-user-menu";
@import "./remaining-time";
@import "./slider-menu";

// Color custom propertys
:root {
  --blue: #{$blue};
  --blueDark: #{$blueDark};
  --green: #{$green};
  --greenDark: #{$greenDark};
  --red: #{$red};
  --redDark: #{$redDark};
  --yellow: #{$yellow};
  --yellowDark: #{$yellowDark};
  --grey10: #{$grey10};
  --bbfcGreen: #{$bbfcGreen};
}

html,
body {
  margin: 0;
  height: 100%;

  > div:not([role="tooltip"]) {
    height: 100%;
  }
}

.root {
  display: flex;
  z-index: 1;
  //overflow: hidden;
  flex-grow: 1;
  margin: {
    right: auto;
  }
  padding-top: 72px;
  position: relative;
  max-width: 100%;
  min-height: 100%;

  @include breakpoint-down($sm) {
    padding-top: 60px;
  }
}

.appBar {
  z-index: 1100;
  background-color: $grey !important;
  color: $white;
  display: flex;
  flex-direction: row !important;
  box-shadow: none !important;

  .toolBar {
    margin-left: auto;
    padding: 0;
    position: relative;
    z-index: 5;

    & > button {
      color: $white;

      svg {
        color: $white;
        fill: $white;
      }
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
/*     background: linear-gradient(135deg,hsla(0,0%,100%,0.1),transparent,hsla(0,0%,0%,0.225));
    background-blend-mode: overlay; */
    background-color: inherit;
  }

  &.menuLess:before {
    margin-left: 15px !important;
  }
}

.toolBar {
  justify-content: flex-end;
}

.logo {
  height: 50px;
  display: block;
}

.drawerPaper {
  position: relative;
  width: 250px;
}

.content {
  z-index: 1000;
  flex-grow: 1;
  padding: 10px;
  min-width: 0;
  transition: margin-left 0.2s ease;

  &,
  & > .react-reveal {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .activityBar ~ & {
    margin-left: 65px;
  }
}

.mainContent {
  position: relative;
  transition: margin-right 0.2s ease;
  flex-grow: 1;

  .filterBar ~ & {
    margin-right: 220px;
  }

  &.fixed {
    display: flex;
    flex-direction: column;
    flex-basis: calc(100vh - 116px);
    height: calc(100vh - 116px);
    flex-grow: 0;
  }
}

.hScroll {
  max-width: 100%;
	overflow-x: auto;
}

.innerHeader {
  display: flex;
  min-height: 80px;

  @include breakpoint-down($md) {
    &.withActions {
      flex-direction: column;
      min-height: 144px;
    }
  }

  &:empty {
    min-height: 0px;
  }
}

.innerHeaderActions {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include breakpoint-down($md) {
    margin-bottom: 16px;
  }

  button,
  a {
    & + button,
    & + a {
      margin-left: 8px;
    }
  }
}

body > div[role="presentation"] > div {
  outline: none;
}

.MenuItemList {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100% - 130px);
  scrollbar-width: thin;
  scrollbar-color: $bbfcGreen $grey10;
}
.MenuItemList::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px,
}
.MenuItemList::-webkit-scrollbar-track {
  background: $grey10,
}
.MenuItemList::-webkit-scrollbar-thumb {
  background: $bbfcGreen,
}
.MenuItemList::-webkit-scrollbar-thumb:hover {
  background: $bbfcGreen,
}

.brandLogo {
  width: 160px;
  height: 64px;
  margin: 0 20px;
  z-index: 3000;
  background-image: url(/assets/images/logos/bbfc-logo-l-w.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  @include breakpoint-down($sm) {
    height: 50px;
    width: 50px;
    margin-left: 10px;
    background-image: url(/assets/images/logos/bbfc-logo-p-w.png);
  }
}
