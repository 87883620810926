.stepper {
  position: sticky;
  top: 64px;
  z-index: 5;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  .completed svg {
    color: #43A047;
  }
  padding: 24px;
}
