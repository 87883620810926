.relatedAppBar {
  margin-top: 20px;
}

.row {
  cursor: pointer,
}

img.advice {
  filter: grayscale(100%);
}
