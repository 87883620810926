@import "../../abstracts";

.editModal {
    @extend %centerFixed;

    max-height: 96vh;
    width: 676px;
    max-width: 100%;
    transform: translate(-50%, -50%);
    padding: {
        top: 12px;
        left: 12px;
        right: 12px;
    }
    outline: none;

    &.smallModal {
        width: 400px;
    }

    .actions {
        //position: sticky;
        //bottom: 0px;
        padding: {
            top: 4px;
            bottom: 12px;
        }
        display: flex;
        justify-content: flex-end;
        background-color: $white;
        margin-top: 30px;

        button + button {
            margin-left: 12px;
        }
    }
}
