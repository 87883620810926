@import '../../../abstracts';

div.dinamicTable {
  // macOS scrollbar overlapping fix
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.35);
    box-shadow: 0 0 1px rgba(255,255,255,.5);
  }

  .details-cell {
    padding: 0;
  }

  .toolbar {
    min-height: 48px;
    padding: {
      left: 8px;
      right: 8px;
    }

    button,
    button svg {
      color: rgba(0, 0, 0, 0.54);
      fill: rgba(0, 0, 0, 0.54);
    }

    button {
      transition: opacity 0.2s ease;
      
      &[disabled] {
        opacity: 0.45;
      }
    }
  }
  
  .toolbar + div,
  .toolbar + div > div:not([class]) {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .paging {
    button {
      font-weight: 400;

      &:not([tabindex="0"]) {
        font-weight: bold;
      }
    }
  }

  table {
    overflow: hidden;
  }

  thead {
    th {
      font: {
        family: Proxima Nova,Roboto,sans-serif;
        size: 1rem;
        weight: normal;
      }
      transition: background-color 0.185s linear;
      padding-left: 8px;
      
      &:hover,
      &:active {
        background-color: rgba(189, 189, 189, 0.15);
      }

      &:empty {
        pointer-events: none;
      }

      &:first-of-type {
        padding-left: 24px;
      }

      span {
        display: flex;
        justify-content: space-between;
      }

      & > div + div {
        display: block;

        div {
          width: 3px !important;
          height: 55px !important;
          top: 0 !important;
          left: 7px !important;
          background-color: rgb(215,215,215);
          transition: background-color 0.185s linear;
        }
        
        div + div {
          width: 1px !important;
          height: 101vh !important;
          left: 9px !important;
          pointer-events: none;
          background-color: transparent;
        }

        &:hover:not(&:hover + div) {

          div + div {
            background-color: rgb(215,215,215);
          }
        }

        &:active {
          div {
            background-color: $blue;
          }
        }
      }
    }
  }

  tbody {

    td a {
      color: $blueDark !important;

      &:hover {
        color: $blue !important;
      }
    }
  }

  /*&.fullScreen {
    position: fixed;
    left: 8px;
    right: 8px;
    top: 8px;
    bottom: 62px;
    z-index: 3500;
    border-radius: 4px 4px 0 0;
    width: auto;
    overflow: hidden;
    margin: 0;
    padding-bottom: 56px;

    & + .sheet {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 3400;
      overflow-y: auto;
      background-color: rgba(black, 0.65);
    }

    .hScroll {
      max-width: 100%;
      overflow: auto;
      height: 100%;
    }

    .pagination {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: white;
    }

    & ~ div > .floatingPanel.embed,
    & ~ .floatingPanel.embed {
      position: fixed;
      bottom: 8px;
      left: 8px;
      right: 8px;
      z-index: 3500;
      width: auto;
      border-radius: 0 0 4px 4px;
    }

    bottom: 8px;
    border-radius: 4px;
  }*/
}
