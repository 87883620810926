@use 'sass:math';
@import '../../abstracts';

.remainingTime {
  $base-color: $white;
  $default-color: $teal;
  $warning-color: $orange;
  $error-color: $red;
  $unit: 20px;
  
  @function timerSize($size, $baseUnit: $unit) {
    $result: math.div($baseUnit, 100) * $size;
    @return $result;
  }
  
  box-sizing: border-box;
  border: timerSize(8) solid $default-color;
  width: timerSize(80);
  height: timerSize(80);
  border-radius: 50%;
  background-color: $base-color;
  overflow: hidden;
  
  &:before,
  &:after {
    content: '';
    position: relative;
    display: none;
    border-color: $default-color;
    border-style: solid;
    width: 0;
    height: 0;
    transform-origin: top center;
  }

  &.t-8 {
    border-color: $default-color;
    background-color: $base-color;
  }
  
  &.t-7 {
    &:before {
      display: block;
      border-width: 0 timerSize(16.5) timerSize(40) timerSize(16.5);
      border-color: transparent transparent $default-color transparent;
      top: 50%;
      left: 50%;
      margin-left: timerSize(-16.5);
      transform: rotate( -180deg + 22.5deg );
    }
  }
  
  &.t-6 {
    &:before {
      display: block;
      border-width: 0 timerSize(40) timerSize(40) timerSize(40);
      border-color: transparent transparent $default-color transparent;
      top: 50%;
      left: 50%;
      margin-left: timerSize(-40);
      transform: rotate( -180deg + 45deg );
    }
  }
  
  &.t-5 {
    border-color: $warning-color;
    
    &:before {
      display: block;
      border-width: 0 timerSize(40) timerSize(40) timerSize(40);
      border-color: transparent transparent $warning-color transparent;
      top: 50%;
      left: 50%;
      margin-left: timerSize(-40);
      transform: rotate( -180deg + 45deg );
    }
    
    &:after {
      display: block;
      border-width: 0 timerSize(16.5) timerSize(40) timerSize(16.5);
      border-color: transparent transparent $warning-color transparent;
      top: 50%;
      left: 50%;
      margin-left: timerSize(-17);
      margin-top: timerSize(-41);
      transform: rotate( -180deg + 112.5deg );
    }
  }
  
  &.t-4 {
    border-color: $warning-color;
    
    &:before {
      display: block;
      top: 0;
      left: 50%;
      width: 50%;
      height: timerSize(80);
      background-color: $warning-color;
      border: none;
    }
  }
  
  &.t-3 {
    background-color: $warning-color;
    border-color: $warning-color;
    
    &:before {
      display: block;
      border-width: 0 timerSize(40) timerSize(40) timerSize(40);
      border-color: transparent transparent $base-color transparent;
      top: 50%;
      left: 50%;
      margin-left: timerSize(-40);
      transform: rotate( -180deg - 45deg );
    }
    
    &:after {
      display: block;
      border-width: 0 timerSize(16.5) timerSize(40) timerSize(16.5);
      border-color: transparent transparent $base-color transparent;
      top: 50%;
      left: 50%;
      margin-left: timerSize(-16.5);
      margin-top: timerSize(-41);
      transform: rotate( 90deg - 22.5deg );
    }
  }
  
  &.t-2 {
    background-color: $error-color;
    border-color: $error-color;
    
    &:before {
      display: block;
      border-width: 0 timerSize(40) timerSize(40) timerSize(40);
      border-color: transparent transparent $base-color transparent;
      top: 50%;
      left: 50%;
      margin-left: timerSize(-40);
      transform: rotate( 134.5deg );
      
    }
  }
  
  &.t-1 {
    background-color: $error-color;
    border-color: $error-color;
    
    &:before {
      display: block;
      border-width: 0 timerSize(16.5) timerSize(40) timerSize(16.5);
      border-color: transparent transparent $base-color transparent;
      top: 50%;
      left: 50%;
      margin-left: timerSize(-16.5);
      transform: rotate( 157.5deg );
    }
  }
  
  &.t-0 {
    background-color: $error-color;
    border-color: $error-color;
  }
}