@import '../abstracts';

.errorPage {
    .breadcrumbs {
        margin: 0;
    }

    .errorCard {
        margin: {
            left: auto;
            right: auto;
        }

        h3,
        p {
            color: $white;
        }

        svg {
            margin-bottom: -4px;
            width: 50px;
            height: 50px;
        }

        button {
            display: block;
        }
    }
}
