.bg-uploader {
  .message {
    display: flex;
    align-items: center;

    .icon {
      opacity: 0.9;
      font-size: 20px;
      margin-right: 6px;
    }

    .link {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .loading-indicator {
    color: #eef3fd;
  }
}