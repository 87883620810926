@import '../abstracts';

div.detailTitle {
  flex-shrink: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: {
      top: 16px;
      left: 16px;
      right: 6px;
      bottom: 16px;
  }
  font: {
      weight: bold;
      size: 1.3em;
  }
  background-color: #eeeeee;
  color: #424242;
  border-radius: 4px 4px 0 0;

  .title {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .twoLines {
    display: flex;
    flex-direction: column;

    small {
      color: rgba($black, 0.333);
      font-size: 0.865rem;
    }
  }

  .logo {
    margin: {
      top: -6px;
      bottom: -6px;
      left: -6px;
      right: 12px;
    }
  }
}
