@import '../../../../abstracts';

div.warningChip {
  display: inline-flex;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  border: {
    color: $red;
    radius: 6px;
  }
  background-color: $red;
  color: $white;

  & > span {
    padding: {
      left: 11px;
      right: 11px;
    }
    font-size: 1.05rem;
  }

  svg {
    width: 16px;
    height: 16px;
    color: $white;
    fill: $white;
  }
}
