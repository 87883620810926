@import "../../abstracts";

.workflow {
  $eventWidth: 50px;
  position: relative;
  font-size: x-small;

  .thread {
    padding: 5px;
    background-color: #eee;

    .title {
      font-weight: bold;
    }

    .decision-container {

      .decision {

        .event {
          background-color: teal;
          align-items: center;
          display: flex;
          height: 20px;
          // margin: 2.5px 0;
          padding-left: 5px;
          border-radius: 6px;
          color: black;
          white-space: nowrap;
          cursor: pointer;
        }
        .event.length-short {
          width: 20px;
          border-radius: 10px;
        }
        @for $i from 1 through 50 {
          .event.length-#{$i} {
            width: ($eventWidth * $i) + 20px
          }
        }

        .event.status-cancelled {
          background-color: #0080807a;
        }
        .event.status-open {
          background-color: #4082ff;
        }
        .event.status-completed {
          // background-color: grey;
        }
        .event.status-failed {
          background-color: $red;
        }
        .event.status-skipped {
          background-color: #0080807a;;
        }
        .event.status-timedOut {
          background-color: $red;
        }

        .event.type-node, .event.type-flowControl, .event.type-switch {
          background-color: #0000001c;
        }
      }

      @for $i from 0 through 500 {
        .decision-num-#{$i} {
          margin-left: $eventWidth * $i
        }
      }
    }
  }

  .thread:nth-child(even) {
    background: #e2e2e2;
  }

  .decisions {
    display: flex;
    padding-left: 5px;
    // position: absolute;
    // top: 0;
    // left: 0;
    // height: 100%;

    .decision {
      width: $eventWidth;
      flex-shrink: 0;
      padding-left: 2px;

      .title {
        cursor: pointer;
      }
    }

    .border {
      position: absolute;
      width: 1px;
      background-color: #1d1d1d12;
      height: 100%;
    }
    .border-num-0 {
      display: none;
    }
    @for $i from 1 through 200 {
      .border-num-#{$i} {
        left: ($eventWidth * $i) + 5px
      }
    }
  }
}