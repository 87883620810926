@import './../abstracts';

.sessionRemainingTime {
  display: flex;
  align-items: center;
  font-size: 0.95rem;
  //min-width: 165px;

  .label {
    margin-right: 6px;
  }

  .hourglass {
    display: none;
    margin-right: 6px;
  }

  @include breakpoint-down(970px) {
    .label {
      display: none;
    }
  
    .hourglass {
      display: inline-block;
    }
  }
}
