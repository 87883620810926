@import '../../abstracts';

.dataCards {
  $borderColor: #d5d5d5;

  border-radius: 6px;
  overflow: hidden;

  .dataCardsHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 16px;
    width: 100%;
    border-bottom: 1px solid $borderColor;
    margin-bottom: -1px;
    position: relative;
    z-index: 10;

    &:empty {
      display: none;
    }

    .orderSelect {
      width: 99px;

      &.compact {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: {
          top: -3px;
          bottom: -3px;
        }
        width: 150px;
        align-items: center;

        label {
          margin-top: 3px;
          position: static;
          transform: scale(0.75);
        }

        & > div {
          margin-top: 0;
        } 
      } 
    } 
  }

  .dataCardsFooter {
    border-top: 1px solid $borderColor;
    margin-top: -1px;
  }

  .dataCardsMeta {
    span {
      display: inline-block;
      color: rgba(0,0,0, 0.55);

      & + span {
        margin-left: 16px;
      }
    }

    svg {
      margin-bottom: -2px;
      fill: rgba(0,0,0, 0.55);
    }

    .open svg {
      fill: $red;
    }

    .closed svg {
      fill: $green;
    }
  }

  .dataCardList {
    position: relative;
    z-index: 5;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align: {
      items: stretch;
      content: stretch;
    }
    margin: {
      bottom: -1px;
      right: -1px;
    }
  }

  .dataCardsHeader:empty + .dataCardList {
    border-top: 1px solid $borderColor;
    border-radius: 6px;
  }

  .dataCard {
    $popTitle: true;

    position: relative;
    display: flex;
    flex-direction: column;
    padding: {
      top: 16px;
      bottom: 24px;
    }
    border: {
      bottom: 1px solid $borderColor;
      right: 1px solid $borderColor;
    }
    
    &.withAction {
      cursor: pointer;
      transition: background-color 0.2s linear;

      &:hover {
        background-color: lighten($borderColor, 12.5%);
      }
    }

    &,
    & ~ div:not(.task) {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 355px;
      min-width: 355px;
      max-width: 715px;
      padding: {
        left: 16px;
        right: 16px;
      }
    }

    & ~ div:not(.dataCard) { 
      border-right: 1px solid transparent;
    }

    @if $popTitle == true {
      .cardTitle {
        margin: {
          top: -16px;
          left: -16px;
          right: -16px;
          bottom: 16px;
        }
        padding: {
          top: 14px;
          left: 16px;
          right: 16px;
          bottom: 4px;
        }

        .title {
          color: inherit;
        }
      }
    
      $headerColor: light;

      $status-neutral: $grey;
      $status-safe: $green;
      $status-alert: $yellow;
      $status-danger: $red;
      $status-neutral-light: lighten($status-neutral, 67.5%);
      $status-safe-light: lighten($status-safe, 50%);
      $status-alert-light: lighten($status-alert, 38.5%);
      $status-danger-light: lighten($status-danger, 50%);

      @if $headerColor == dark {
        .cardTitle {
          background-color: $status-neutral;
          background-image: 
            linear-gradient(
              135deg, 
              lighten($status-neutral, 2.5%),
              transparent,
              darken($status-neutral, 2%)
            )
          ;
          margin-right: -17px;
          color: white;
        }

        // Safe color
        &.submission-card-status-SUCCEEDED,
        &.submission-card-status-ALLOCATION_CONFIRMED,
        &.submission-card-status-COMPLETED,
        &.submission-card-status-OK {
          .cardTitle {
            background-color: $status-safe;
            background-image: 
              linear-gradient(
                135deg, 
                lighten($status-safe, 2.5%),
                transparent,
                darken($status-safe, 2%)
              )
            ;
          }
        }

        // Alert color
        &.submission-card-status-ALLOCATION_CONFIRMATION,
        &.submission-card-status-COMPLIANCE_REVIEW,
        &.submission-card-status-WAITING_FOR_VIDEO,
        &.submission-card-status-WAITING_FOR_ALLOCATION,
        &.submission-card-status-DELETED,
        &.submission-card-status-HOLD,
        &.submission-card-status-WAITING_FOR_SOMETHING {
          .cardTitle {
            background-color: $status-alert;
            background-image: 
              linear-gradient(
                135deg, 
                lighten($status-alert, 2.5%),
                transparent,
                darken($status-alert, 2%)
              )
            ;
          }
        }

        // Danger color
        &.submission-card-status-NEW,
        &.submission-card-status-VIDEO_PROVIDING_EXPIRED,
        &.submission-card-status-CANCELLED,
        &.submission-card-status-ALLOCATION_REFUSED,
        &.submission-card-status-COMPLIANCE_REVIEW_CONFIRMATION,
        &.submission-card-status-BLOCKED,
        &.submission-card-status-GOING_TO_EXPIRE {
          .cardTitle {
            background-color: $status-danger;
            background-image: 
              linear-gradient(
                135deg, 
                lighten($status-danger, 2.5%),
                transparent,
                darken($status-danger, 2%)
              )
            ;
          }
        }
      }
    
      @if $headerColor == light {
        .cardTitle {
          background-color: $status-neutral-light;
          background-image: 
              linear-gradient(
                135deg, 
                lighten($status-neutral-light, 2.5%),
                transparent,
                darken($status-neutral-light, 2%)
              )
            ;
          color: #434343;
        }

        // Safe color
        &.submission-card-status-SUCCEEDED,
        &.submission-card-status-ALLOCATION_CONFIRMED,
        &.submission-card-status-COMPLETED,
        &.submission-card-status-OK {
          .cardTitle {
            background-color: $status-safe-light;
            background-image: 
              linear-gradient(
                135deg, 
                lighten($status-safe-light, 2.5%),
                transparent,
                darken($status-safe-light, 2%)
              )
            ;
          }
        }

        // Alert color
        &.submission-card-status-ALLOCATION_CONFIRMATION,
        &.submission-card-status-COMPLIANCE_REVIEW,
        &.submission-card-status-WAITING_FOR_VIDEO,
        &.submission-card-status-WAITING_FOR_ALLOCATION,
        &.submission-card-status-DELETED,
        &.submission-card-status-HOLD,
        &.submission-card-status-WAITING_FOR_SOMETHING {
          .cardTitle {
            background-color: $status-alert-light;
            background-image: 
              linear-gradient(
                135deg, 
                lighten($status-alert-light, 2.5%),
                transparent,
                darken($status-alert-light, 2%)
              )
            ;
          }
        }

        // Danger color
        &.submission-card-status-NEW,
        &.submission-card-status-VIDEO_PROVIDING_EXPIRED,
        &.submission-card-status-CANCELLED,
        &.submission-card-status-ALLOCATION_REFUSED,
        &.submission-card-status-COMPLIANCE_REVIEW_CONFIRMATION,
        &.submission-card-status-BLOCKED,
        &.submission-card-status-GOING_TO_EXPIRE {
          .cardTitle {
            background-color: $status-danger-light;
            background-image: 
              linear-gradient(
                135deg, 
                lighten($status-danger-light, 2.5%),
                transparent,
                darken($status-danger-light, 2%)
              )
            ;
          }
        }
      }
    }

    .title {
      flex-grow: 1;
      font-size: 16px;
      padding-bottom: 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .statusChip {
        margin-top: -3px;
        margin-left: auto;
      }

      .titleText {
        line-height: 1.25em;
      } 

      .headerActions {
        margin-left: auto;

        button, a {
          margin: {
            top: -4px;
            bottom: -4px;
            right: -4px;
          }

          & + button, 
          & + a {
            margin-left: 12px;
          } 
        }
      }
    }

    .logo {
      flex-grow: 0;
      flex-shrink: 0;
      display: block;
      height: 46px;
      margin-right: 8px;

      & > * {
        background-color: white;
      }
    }

    .action a {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      color: transparent;
      font-size: 0;
    }

    .deleted {
      text-decoration: line-through;
    }
  }

  .cardContent {
    padding-top: 8px;
  }

  .cardRow  {
    display: block;
    margin-left: 1px;
    color: rgba(0,0,0, 0.75);
    font-size: 0.9rem;
    line-height: 1.5em;
    width: auto;
    
    & + .cardRow {
      margin-top: 2px;
    }

    svg {
      font-size: inherit;
      margin-bottom: -2px;
      fill: rgba(0,0,0, 0.55);
    }

    a {
      position: relative;
      z-index: 50;
    }
  }
}

