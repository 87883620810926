@import '../../abstracts';

.details-cell {
  &:hover {
    background-color: $white !important;
  }
}

.clientOrdersRowDetail {
  margin: {
    top: 16px;
    right: 16px;
    bottom: 16px;
    left: 56px;
  }
  border: 1px solid lighten($black, 90%);
  overflow: hidden;

  table {
    margin-bottom: -1px;

    th,
    td {
      padding: {
        left: 16px !important;
        right: 16px !important;
        top: 8px !important;
        bottom: 8px !important;
      }
      /*height: 10px !important;
      min-height: 0px !important;
      max-height: 20px !important;*/
    }

    th {
      font-size: 0.9rem !important;
    }

    td {
      //width: 100%;

      &.l {
        width: 320px;
      }

      &.m {
        width: 220px;
      }

      &.s {
        width: 140px;
      }
    }

    thead tr {
      height: 36px !important;
    }

    tbody tr {
      height: 36px !important;
    }
  }
}
