@import "../../variables";

.strength-indicator {
  .dot {
    color: $teal;
    font-size: 18px;
  }
}

.longRatingsInfo-strength {
  display: flex !important;
  justify-content: space-between;
}