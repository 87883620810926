/* Utilites */

@import "./extends";

// Center elements
.center {
    @extend %center;
}

.centerAbsolute {
    @extend %centerAbsolute;
}

.centerFixed {
    @extend %centerFixed;
}

// Icons
svg.iconTiny {
    width: 14px;
    height: 14px;
}

svg.iconSmall {
    width: 21px;
    height: 21px;
}

svg.iconLarge {
    width: 38px;
    height: 38px;
}

.iconLeft {
    margin-right: 0.333rem;

    .iconTiny & {
        margin-right: 0.15rem; 
    }

    .iconSmall & {
        margin-right: 0.222rem; 
    }
}
  
.iconRight {
    margin-left: 0.333rem;

    .iconTiny & {
        margin-left: 0.15rem; 
    }

    .iconSmall & {
        margin-left: 0.222rem; 
    }
}


.svgIcon {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;
    transform-origin: 0px 0px 0px;
}

.iconHeading {
    color: inherit;
}

// Buttons
button.tinyButton,
a.tinyButton {
    line-height: 22px;
    padding: 2px 4px;
}

// Grid
.col-align-center {
    display: flex;
    align-items: center;
}

.row-align-center {
    & > div {
        @extend .col-align-center;
    }
}

.col-justify-end {
    display: flex;
    justify-content: flex-end;
}

.col-justify-center {
    display: flex;
    justify-content: center;
}

.col-justify-space-between {
    display: flex;
    justify-content: space-between;
}

.row-spacing {
    padding: {
        top: 8px;
        bottom: 8px;
    }
}

.row-spacing2 {
    padding: {
        top: 14px;
        bottom: 14px;
    }
}

.row-spacing3 {
    padding: {
        top: 21px;
        bottom: 21px;
    }
}

.row-highlight {
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        left: -0.5em;
        right: -0.5em;
        top: 7px;
        bottom: 7px;
        display: block;
        transition: background-color 0.2s linear;
        pointer-events: none;
    }

    &:hover:before {
        background-color: rgba(0, 0, 0, 0.0333);
    }
}

.row-header {
    p {
        font-size: 1.1rem;
        font-weight: 500;
        line-height: 1.5em;
    }
}

.row-header-sticky {
    position: sticky;
    z-index: 450;
    top: 55px;

    @include breakpoint-down($sm) {
        top: 45px;
    }

    & > div {
        background-color: #fff;
    }

    & + hr {
        position: sticky;
        z-index: 450;
        top: 105px;

        @include breakpoint-down($sm) {
            top: 100px;
        }
    }
}

hr.row-divider {
    margin: {
        left: -0.5em;
        right: -0.5em;
    }
}


body,
div.root {
    // Display
    $displays: block inline-block inline flex none;

    @each $display in $displays {
        .d-#{$display} {
            display: $display;
        }
    }

    // Spacer
    $sizes: 8px 16px 24px 32px 40px;

    @each $size in $sizes {
        $i: index($sizes, $size);
        // margin
        .mt-#{$i} {
            margin-top: $size;
        }

        .mb-#{$i} {
            margin-bottom: $size;
        }

        .my-#{$i} {
            @extend .mt-#{$i};
            @extend .mb-#{$i};
        }

        .ml-#{$i} {
            margin-left: $size;
        }

        .mr-#{$i} {
            margin-right: $size;
        }

        .mx-#{$i} {
            @extend .ml-#{$i};
            @extend .mr-#{$i};
        }

        .m-#{$i} {
            @extend .my-#{$i};
            @extend .mx-#{$i};
        }

        // padding
        .pt-#{$i} {
            padding-top: $size;
        }

        .pb-#{$i} {
            padding-bottom: $size;
        }

        .py-#{$i} {
            @extend .pt-#{$i};
            @extend .pb-#{$i}
        }

        .pl-#{$i} {
            padding-left: $size;
        }

        .pr-#{$i} {
            padding-right: $size;
        }

        .px-#{$i} {
            @extend .pl-#{$i};
            @extend .pr-#{$i};
        }

        .p-#{$i} {
            @extend .py-#{$i};
            @extend .px-#{$i};
        }
    }

    .mt-0 {
        margin-top: 0;
    }

    .mb-0 {
        margin-bottom: 0;
    }

    .ml-0 {
        margin-left: 0;
    }

    .mr-0 {
        margin-right: 0;
    }

    .my-0 {
        @extend .mt-0;
        @extend .mb-0;
    }

    .mx-0 {
        @extend .mr-0;
        @extend .ml-0;
    }

    .m-0 {
        @extend .my-0;
        @extend .mx-0;
    }

    .pt-0 {
        padding-top: 0;
    }

    .pb-0 {
        padding-bottom: 0;
    }

    .pl-0 {
        padding-left: 0;
    }

    .pr-0 {
        padding-right: 0;
    }

    .py-0 {
        @extend .pt-0;
        @extend .pb-0;
    }

    .px-0 {
        @extend .pr-0;
        @extend .pl-0;
    }

    .p-0 {
        @extend .py-0;
        @extend .px-0;
    }

    .mt-auto {
        margin-top: auto;
    }

    .mb-auto {
        margin-bottom: auto;
    }

    .my-auto {
        @extend .mt-auto;
        @extend .mb-auto;
    }

    .ml-auto {
        margin-left: auto;
    }

    .mr-auto {
        margin-right: auto;
    }

    .mx-auto {
        @extend .ml-auto;
        @extend .mr-auto;
    }

    .m-auto {
        @extend .my-auto;
        @extend .mx-auto;
    }

    .m-medium {
        margin: {
            top: 16px;
            bottom: 8px;
        }
    }

    // Color utilities
    .white {
        color: $white;
    }
    .blue {
        color: $blue;
    }
    .blueDark {
        color: $blueDark;
    }
    .green {
        color: $green;
    }
    .greenDark {
        color: $greenDark;
    }
    .yellow {
        color: $yellow;
    }
    .yellowDark {
        color: $yellowDark;
    }
    .red {
        color: $red;
    }
    .redDark {
        color: $redDark;
    }
    .teal {
        color: $teal;
    }
    .grey {
        color: $grey;
    }
    .whiteBg {
        background-color: $white;
    }
    .blueBg {
        background-color: $blue;
    }
    .blueDarkBg {
        background-color: $blueDark;
    }
    .greenBg {
        background-color: $green;
    }
    .greenDarkBg {
        background-color: $greenDark;
    }
    .yellowBg {
        background-color: $yellow;
    }
    .yellowDarkBg {
        background-color: $yellowDark;
    }
    .redBg {
        background-color: $red;
    }
    .redDarkBg {
        background-color: $redDark;
    }
    .greyBg {
        background-color: $grey;
    }
    .tealBg {
        background-color: $teal;
    }
}

// Width
.fullWidth {
    width: 100%;
}

$widths: 100 75 50 25 20;

@each $width in $widths {
    .w-#{$width} {
        width: #{$width * 1%};
        min-width: #{$width * 1%};
    }
}

// Badges
span.inlineBadge span {
    position: static;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    display: inline-block;
    text-align: center;
    height: 16px;
    min-width: 16px;
    line-height: 17px;
    border-radius: 10px;
    padding: {
        left: 4px;
        right: 4px;
    }
    transform: translateY(-2px);
    margin: {
        top: -3px;
        bottom: -3px;
    }
}

// Tables
table.hide-last-line tbody tr:last-of-type th,
table.hide-last-line tbody tr:last-of-type td {
    border-bottom: none;
}

// Expand
button.expand-button {
    transform: rotate(0turn);
    transition: 
        transform 0.155s linear 0s, 
        background-color 0.15s linear 0s
    ;

    &.expand-button-active {
        transform: rotate(0.5turn);
    }
}

// FlexBox
.direction-row {
    flex-direction: row;
}

.direction-column {
    flex-direction: column;
}

.align-start {
    align-items: flex-start;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.justify-start {
    justify-content: flex-start;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-end {
    justify-content: flex-end;
}

// Hide empty element
.hideEmpty:empty {
    display: none !important;
}

// inputs
.multiline {
    & > div div {
        white-space: normal;
    }
}

.multilineItem {
    height: auto !important;
    white-space: normal !important;
    max-width: 330px;
}

// Text
.noWrap {
    white-space: nowrap;
}

.wrap {
    white-space: normal;
}

// Overflow
.overflowHidden {
    overflow: hidden !important;
}

.overflowVisible {
    overflow: visible !important;
}
