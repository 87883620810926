body .switchBase {
  &:not(.switchDisabled) {
    .switchIcon {
      background-color: $red;
    }
  
    &.switchChecked {
      & + .switchBar {
        background-color: $greenDark;
      }
  
      .switchIcon {
        background-color: $green;
      }
    }
  }
  
  &.switchDisabled {
    &.switchChecked {
      & + .switchBar {
        background-color: $blueDark;
      }
  
      .switchIcon {
        background-color: $blue;
      }
    }
  }
}
