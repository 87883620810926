.companyFormBlock:before { 
  content: attr(data-title);
  font: {
    size: 18px;
    weight: bold;
  }
}

.usersLabel {
  position: static !important;
  transform: translate(0, 0) scale(1) !important;
}

div.companyFormSelect {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  border-top: 1px solid white;

  & > div {
    display: flex;
    flex-wrap: wrap;

    & > div {
      //order: 10;

      & ~ input {
        margin-top: 12px;
      }
    }

    input {
      //order: 5;
      width: 100%;
    }
  }
}
