.wrapper {
  height: 10em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contentPlaceholder .error {
  color: rgba(0, 0, 0, 0.24) !important;
}
.error .icon {
  font-size: 1em !important;
}