#videoList {
  padding: 0;
  
  button {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .details {
      display: flex;
      flex-direction: column;
    }
  }

  .videoListCard{
    flex-grow: 1;

    button > img {
      width: 120px;
    }
  }
}

.loadMore {
  button {
    max-width: inherit;
  }
}
