.dynamicTableLoading {
    display: block;
    order: 100;
    height: 110px;
    
    &,
    .dynamicTableLoadingInner {
        width: 99.85%;
        overflow: hidden;
    }

    .dynamicTableLoadingInner {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: {
            top: 32px;
            bottom: 32px;
        }
        background-color: white;
    }

    div > & {
        margin-top: -44px;
    }

    tr > & {
        .dynamicTableLoadingInner {
            position: absolute;
        }
    }
}
