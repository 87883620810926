@import "../../abstracts";

nav.compactCardContainer {
  $borderColor: #d5d5d5;

  display: flex;
  flex-wrap: wrap;
  margin: {
    top: -16px;
    left: -17px;
    right: -17px;
    bottom: -25px;
  };
  border: {
    width: 1px 0 0 1px;
    style: solid;
    color: rgba($borderColor, 0.75);
  }

  .compactCard {
    align-items: flex-start;
    width: 100%;
    border: {
      width: 0 1px 1px 0;
      style: solid;
      color: $borderColor;
    }
  
    @include breakpoint-up($sm) {
      width: 50%;
    }
  
    @include breakpoint-up($md) {
      width: 33.333%;
    }
  
    @include breakpoint-up($lg) {
      width: 25%;
    }
  
    @include breakpoint-up($xl) {
      width: 25%;
    }

    .compactCardContent {
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      min-width: 0;
    }

    .userName {
      font-size: 1rem;
      line-height: 1.5;
    }

    .email {
      margin-bottom: 5px;
      min-width: 0;
      max-width: 100%;
      font-size: 0.8666rem;

      span {
        display: block;
        max-width: 100%;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .userRole {
      color: rgba(0, 0, 0, 0.54);
      font-size: 0.875rem;
      line-height: 1.5;
    }
  }
}

