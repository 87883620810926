@import "../abstracts";

.landingPageCover {
  position: fixed;
  left: 0;
  right: 0;
  top: 64px;
  bottom: 0;

  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: none;

  background: {
    image: url(/assets/images/backgrounds/horizon.jpg);
    repeat: no-repeat;
    position: center;
    size: cover;
    color: $black;
  }

  @include breakpoint-down($sm) {
    top: 52px;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.landingPageContent {
  padding: 20px;

  h1 {
    font: {
      size: 4rem;
      weight: 500;
    }
  }

  @include breakpoint-down($md) {
    h1 {
      font-size: 2.5rem;
    }
  }

  @include breakpoint-down($sm) {
    h1 {
      font-size: 2.25rem;
      color: $white;
    }
  }
}
