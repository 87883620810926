@import '../../abstracts';

#addFormCard {  
  background-color: #ffffff;
  color: $addFormColor;
  box-shadow: 0px 0px 20px rgba(116, 127, 139, 0.2);
  border-radius: 16px 16px 0px 0px;

  .loader {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    z-index: 7;
  }

  #header {
    padding: 16px 40px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0px 0px 20px rgba(116, 127, 139, 0.2);
    z-index: 6;
    background-color: #ffffff;
  }

  #footer {
    padding: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    border: 1px solid #D5D9DC;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(144, 152, 162, 0.2);
    border-radius: 16px 16px 0px 0px;
  }

  label,
  p,
  h5,
  h6 {
    // color: black;  //$addFormColor;
  }

  h5,
  h6 {
    font-weight: bold;
  }

  a {
    color: $addFormColor;
  }

  .actions button {
    min-width: 120px;
    background-color: $addFormColor;
    color: $addFormBgColor;
    line-height: 1rem;

    &:hover {
      background-color: darken($addFormColor, 5%);
    }

    &[disabled] {
      background-color: rgba($addFormColor, 0.45);
      color: rgba($addFormBgColor, 0.45);
    }
  }

}

body > div[role="presentation"] #addFormCard {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc( 100vw - 50px );
  max-width: 860px;
  max-height: calc( 100vh - 50px);
  overflow-y: auto;
}

.episodesContainer {
  border-bottom: 1px solid #D5D9DC;
  padding-bottom: 16px;
}

.deleteEpisode {
  display: flex;
  align-items: center;
  float: right;
  color: #08918c;
  cursor: pointer;
}

.deleteLink {
  border-bottom: 1px solid #08918c;
  margin: 0px 0px 0px 10px;
}

.episodesActions {
  padding: 20px 0px;
}

.termsAndConditions {
  color: #08918c;
}

