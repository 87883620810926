/* Mixins */

// Responsive breakpoints
// xs, extra-small: 0px or larger
// sm, small: 600px or larger
// md, medium: 960px or larger
// lg, large: 1280px or larger
// xl, extra-large: 1920px or larger

@mixin breakpoint-up($width) {
    @media (min-width: $width) {
        @content;
    }
}

@mixin breakpoint-down($width) {
    @media (max-width: ($width - 1px)) {
        @content;
    }
}

@mixin breakpoint-btw($min-width: $xs, $max-width: $xl) {
    @media (min-width: $min-width) and (max-width: ($max-width - 1px)) {
        @content;
    }
}
