@import '../../abstracts';

button.cencelButton:not([disabled]) {
    color: $red;
    border-color: $red;
    fill: $red;

    svg {
        fill: inherit;
    }
}

button.error {
    color: $red;
    border-color: $red;
    fill: $red;

    svg {
        fill: inherit;
    }
}