@import '../../abstracts';

.messageGridItem .message {
  $borderColor: #d5d5d5;
  position: relative;
  border: {
    width: 1px;
    style: solid;
    color: $borderColor;
    radius: 4px
  }
  padding: 16px;
  margin: {
    top: 24px;
    bottom: 24px;
    left: 68px;
    right: 37px;
  }

  @include breakpoint-down($md) {
    margin: {
      left: 80px;
      right: 60px;
    }
  }

  a {
    color: #004d92;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }

  h1 {
    display: flex;
    justify-content: space-between;

    font: {
      weight: 500;
      size: 1em;
    }

    span,
    small {
      line-height: 1.2rem;
    }

    small {
      font-weight: normal;
      text-align: right;
    }
  }

  .size-sm {
    display: none;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    z-index: 5;
  }

  .line {
    display: none;
  }

  .sender {
    position: absolute;
    z-index: 10;
  
    p {
      position: absolute;
      top: 110%;
      left: 50%;
      transform: translateX(-50%);
      width: 140%;

      text-align: center;
      font: {
        weight: bold;
        size: 0.75rem;
      }
    }

    &:hover p {
      display: block;
    }
  }

  &.from {
    border-radius: 0 4px 4px 4px;

    &:before {
      top: -1px;
      right: 100%;
      border:  {
        style: solid;
        width: 6px;
        color: $borderColor $borderColor transparent transparent ;
      }
    }

    &:after {
      top: 0;
      right: 100%;
      border:  {
        style: solid;
        width: 5px;
        color: $white $white transparent transparent;
      }
    }

    .sender {
      top: -23px;
      left: -60px;
    }
  }

  &.to {
    border-radius: 4px 0 4px 4px;

    &:before {
      top: -1px;
      left: 100%;
      border:  {
        style: solid;
        width: 6px;
        color: $borderColor transparent transparent $borderColor;
      }
    }

    &:after {
      top: 0;
      left: 100%;
      border:  {
        style: solid;
        width: 5px;
        color: $white transparent transparent $white;
      }
    }

    .sender {
      top: -23px;
      right: -60px;
    }
  }

  .messageBody {
    *:last-child {
      margin-bottom: 0;
    }

    h1, h2, h3, h4, h5, h6 {
      margin: {
        top: 1em;
        bottom: 1em;
      }
    }

    h1 {
      font-size: 1.4rem;
    }

    h2 {
      font-size: 1.25rem;
    }

    h3 {
      font-size: 1.15rem;
    }

    h4 {
      font-size: 1rem;
    }

    p, h5, h6 {
      font-size: 0.9rem;
  
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  
  .messageFooter {
    margin: {
      top: 16px;
      right: -16px;
      bottom: -16px;
      left: -16px;
    }
    border-top: 1px solid $borderColor;
    padding: {
      top: 8px;
      right: 16px;
      bottom: 8px;
      left: 16px;
    }
    display: flex;
  }

  .messageAttachment {
    flex-grow: 1;
  }

  .messageActions {
    align-self: flex-end;
  }
  
  .downloadFile {
    cursor: pointer;
  
    .fileName {
      align-items: center;
      display: flex;
    }
  }
}


