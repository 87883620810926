@import '../../../../abstracts';

$fullwidth: true;

@mixin chooserLayout() {
  flex-grow: 1;
  margin: {
    top: 0;
    left: 8px;
    right: 8px;
    bottom: 16px;
  }
  min-width: 240px;
  flex-basis: 240px;
  max-width: 650px;
  

  @include breakpoint-down($md) {
    min-width: calc(100% - 16px);
  }

  @if $fullwidth == true {
    min-width: calc(100% - 16px);
  }
}

nav.chooserTaskList {
  display: flex;
  flex-wrap: wrap;
  margin: {
    left: -8px;
    right: -8px;
  }
  width: calc(100% + 16px);
}

div.chooserTaskItem {
  @include chooserLayout();

  display: flex;
  flex-wrap: wrap;
  padding: {
    left: 16px;
    right: 16px;
  }
  background-color: darken($addFormBgColor, 2.5%);
  border-radius: 6px;
  order: 20;

  .submissionFee {
    color: $addFormBgColor !important;
    font-weight: bold;
  }

  &.type-holding {
    order: 10;

    background-color: $redDark;

    & ~ .holdingDateText,
    & ~ .alternateDateText {
      display: flex !important;

      p {
        font-weight: bold;
        font-size: 1.05rem;
      }

      b {
        font-weight: bolder;
        text-decoration: underline;
      }
    }

    & ~ .holdingDateText {
      order: 5;
      align-items: flex-start;
    }

    & ~ .alternateDateText {
      order: 15;
    }

    &.selected {
      background-color: $red;
    }
  }

  &.type-dataChaged {
    order: 30;

    & ~ .alternateDateText {
      display: flex !important;
      order: 25;

      p {
        font-weight: bold;
        font-size: 1.05rem;
      }

      b {
        font-weight: bolder;
        text-decoration: underline;
      }
    }
  }

  .checkBox {
    margin: {
      top: -6px;
      bottom: -10px;
      left: -15px;
      right: -16px;
    }

    svg {
      fill: $addFormColor;
    }
  }

  &.selected:not(.type-holding) {
    background-color: $addFormColor;

    p {
      color: $grey !important;
    }

    .date span {
      color: $addFormBgColor;
    }

    .checkBox {
      svg {
        fill: $addFormBgColor;
      }
    }

    .metaFormSelect {
      #addFormCard & {
        & > div {
          &:after,
          &:before {
            border-color: $addFormBgColor !important;
          }
        }

        div, svg, p {
          color: $addFormBgColor !important;
        }
      }
    }
  }

  .date {
    margin-top: 5px;

    span {
      font: {
        weight: bold;
        size: 1.15rem;
      }
      color: $addFormColor;
    }
  }
}

.chooserTaskItem ~ div:not(.chooserTaskItem) {
  @include chooserLayout();

  margin-bottom: 0;
}

.chooserCutOffDate {
  margin-top: 0;
  padding: {
    left: 33px;
    bottom: 8px;
  }
  width: 100%;

  p {
    font-size: 1.185rem;
    font-weight: 500;
    color: $grey;

    svg {
      margin-bottom: -2px;
    }

    span,
    time {
      text-decoration: underline $teal;
      color: $teal;
      fill: $teal;
      white-space: nowrap;
    }
  }
} 

.cutOffDateSelector {
  display: flex;
  align-items: center;

  p {
    font-size: 0.95rem;
  }

  svg {
    .selected:not(.type-holding) & {
      fill: $grey;
      color: $grey;
    }
  }

  .metaFormSelectContainer {
    flex-basis: 285px;
    display: flex;
    align-items: center;
  }
}

.chooserTaskPrice {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 8px;
  border-top: 1px solid rgba(black, 0.125);
  width: 100%;

  .cpm {
    flex-grow: 1;
    margin-right: 16px;
  }

  .description {
    text-align: left;
    margin-bottom: 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(black, 0.125);
  }

  .price {
    display: flex;
    align-items: center;
    justify-content: flex-end;


  }

  .priceChip {
    font: {
      size: 20px;
      weight: normal;
    }
    background-color: $addFormColor;
    color: black;

    .selected:not(.type-holding) & {
      background-color: $addFormBgColor;
      color: $addFormColor;
    }

    .selected.type-holding & {
      background-color: $redDark;
      color: $white;
    }
  }

  @if $fullwidth == true {
    flex-direction: row;

    .description {
      flex-grow: 1;
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}
