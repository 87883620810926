@import '../../abstracts';

// Submission timeline
.timeline {
    $status-neutral: $grey;
    $status-safe: $green;
    $status-alert: $yellow;
    $status-danger: $red;

    $duration: 0.575s;

	position: relative;
    margin: {
        left: 160px;
        right: 160px;
    }

    @include breakpoint-down(700px) {
        margin: 0;
    }
    
    ul {
        position: relative;
        height: 8px;
        width: 100%;
        max-width: 620px;
        margin: 150px auto;
        padding: 0;
        list-style-type: none;
        background-color: #adb5bd;

        @include breakpoint-down(700px) {
            width: 100%;
            max-width: none;
            height: auto;
            margin: 0;
            background-color: transparent;
        }
    }

    li.date {
        position: absolute;
        z-index: 10;
        padding: 10px 8px 6px 8px;
        background-color: #e9ecef;
        width: 100%;
        max-width: 180px;
        text-align: left;

        &:after {
            content: '';
            display: block;
            position: absolute;
            z-index: 10;
            
            width: 0;
            height: 0;
            border-style: solid;
            transform: border-color 0.2s ease;

            @include breakpoint-up(700px) {
                animation: {
                    name: popDateTriangle;
                    duration: ($duration * 0.5);
                    iteration-count: 1;
                    timing-function: ease;
                }
            }
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: 10;
            width: 16px;
            height: 16px;
            background-color: #adb5bd;
            border: 2px solid white;
            border-radius: 50%;
            transition: transform 0.2s ease;

            @include breakpoint-up(700px) {
                animation: {
                    name: popDateDot;
                    duration: ($duration * 1.2);
                    iteration-count: 1;
                    timing-function: ease;
                }
            }
        }

        &:hover {
            z-index: 20;
            &:before {
                transform: scale(1.333);
            }
        }

        @include breakpoint-up(700px) {
            animation: {
                name: popDate;
                duration: $duration;
                iteration-count: 1;
                timing-function: ease;
            }
        }

        /* #1 */
        &:first-child {
            bottom: 100%;
            margin-bottom: 23px;
            
            border-radius: 4px 4px 0 4px;
            transform: translateX(-100%);
            transform-origin: bottom;

            &:after {
                right: 0;
                top: 100%;
                border: {
                    width: 8px;
                    color: #e9ecef #e9ecef transparent transparent;
                }
            }

            &:before {
                right: -8px;
                top: 100%;
                margin-top: 19px;
            }
        }

        /* #2 */
        &:nth-child(2):not(:nth-last-child(2)) {
            top: 100%;
            margin-top: 23px;
            
            border-radius: 4px 0 4px 4px;
            transform: translateX(-100%);
            transform-origin: bottom;

            &:after {
                right: 0;
                bottom: 100%;
                border: {
                    width: 8px;
                    color: transparent #e9ecef #e9ecef transparent ;
                }
            }

            &:before {
                right: -8px;
                bottom: 100%;
                margin-bottom: 19px;
            }
        }

        /* #3 */
        &:nth-child(3):not(:nth-last-child(2)) {
            bottom: 100%;
            margin-bottom: 23px;
            
            border-radius: 4px;
            transform: translateX(-50%);
            transform-origin: bottom;

            &:after {
                left: 50%;
                top: 100%;
                margin-left: -10px;
                border: {
                    width: 16px 10px 0 10px;
                    color: #e9ecef transparent transparent transparent;
                }
            }

            &:before {
                left: 50%;
                top: 100%;
                margin: {
                    left: -8px;
                    top: 19px;
                }
            }
        }

        /* #4 */
        &:nth-child(4):not(:nth-last-child(2)) {
            top: 100%;
            margin-top: 23px;
            
            border-radius: 4px;
            transform: translateX(-50%);
            transform-origin: top;

            &:after {
                left: 50%;
                bottom: 100%;
                margin-left: -10px;
                border: {
                    width: 0 10px 16px 10px;
                    color: transparent transparent #e9ecef transparent;
                }
            }

            &:before {
                left: 50%;
                bottom: 100%;
                margin: {
                    left: -8px;
                    bottom: 19px;
                }
            }
        }
        
        /* #5 */
        &:nth-child(5):not(:nth-last-child(2)),
        &:nth-child(3):nth-last-child(2) {
            bottom: 100%;
            margin-bottom: 23px;
            
            border-radius: 4px 4px 4px 0;
            transform-origin: bottom;

            &:after {
                left: 0;
                top: 100%;
                border: {
                    width: 8px;
                    color: #e9ecef transparent transparent #e9ecef;
                }
            }

            &:before {
                left: -8px;
                top: 100%;
                margin-top: 19px;
            }
        }

        /* #6 */
        &:nth-last-child(2):not(:nth-child(3)):not(:first-child) {
            top: 100%;
            margin-top: 23px;
            
            border-radius: 0 4px 4px 4px;
            transform-origin: bottom;

            &:after {
                left: 0;
                bottom: 100%;
                border: {
                    width: 8px;
                    color: transparent transparent #e9ecef #e9ecef;
                }
            }

            &:before {
                left: -8px;
                bottom: 100%;
                margin-bottom: 19px;
            }
        }
    
        &.status-danger {
            background-color: $status-danger;

            &,
            & * {
                color: white;
            }

            .time {
                border-bottom-color: white;
            }

            &:before {
                background-color: $status-danger;
            }

            &:after {
                border-color: $status-danger transparent transparent $status-danger;
            }

    
            &:nth-child(1):after {
                border-color: $status-danger $status-danger transparent transparent;
            }

            &:nth-child(2):not(:nth-last-child(2)):after {
                border-color: transparent $status-danger $status-danger transparent;
            }
    
            &:nth-child(3):not(:nth-last-child(2)):after {
                border-color: $status-danger transparent transparent transparent;
            }
            &:nth-child(4):not(:nth-last-child(2)):after {
                border-color: transparent transparent $status-danger transparent;
            }
            &:nth-child(5):not(:nth-last-child(2)):after,
            &:nth-child(3):nth-last-child(2):after {
                border-color: $status-danger transparent transparent $status-danger;
            }
            &:nth-last-child(2):not(:nth-child(3)):not(:first-child):after {
                border-color: transparent transparent $status-danger $status-danger;
            }
        }


        @include breakpoint-down(700px) {
            width: 100%;
            max-width: none;
            position: static;
            display: block;
            margin: 0 0 5px 0 !important;
            transform: translateX(0) !important;
            border-radius: 4px !important;
            
            &:before,
            &:after {
                display: none;
            }
        }

        span.time {
            display: flex;
            align-items: flex-end;
            border-bottom: 1px solid #ced4da;
            padding-bottom: 8px;
            
            b {
                font-size: 23px;
                line-height: 18px;
                margin-right: 4px;
            }
            
            .my {
                font-size: 12.5px;
                line-height: 12px;

                br {
                    display: none;
                }
            }

            @include breakpoint-up(700px) {
                animation: {
                    name: popDateInnerBorder;
                    duration: ($duration * 1.2);
                    iteration-count: 1;
                    timing-function: ease;
                }
            }

        }
        
        span.label {
            margin-top: 4px;
            font: {
                weight: 400;
                size: 14.5px;
            }
            display: block;
        }

        b,
        .my,
        .label {
            @include breakpoint-up(700px) {
                animation: {
                    name: popText;
                    duration: ($duration * 1.2);
                    iteration-count: 1;
                    timing-function: ease;
                }
            }
        }

        .entry {
            display: block;
            padding-left: 11px;
            text-indent: -11px;

            &:before {
                content: '';
                display: inline-block;
                margin: {
                    right: 4px;
                    bottom: 1px;
                }
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $black;
            }

            &.status-danger {
                color: $status-danger;

                &:before {
                    background-color: $status-danger;
                }
            }
        }
    }
    
    li.today {
        padding-top: 3px;
        position: absolute;
        top: 100%;
        transform: translateX(-50%);
        z-index: 10;
        font-size: 13.5px;
        width: auto;
        max-width: 100px;
        color: $teal;
        border-radius: 6px;

        &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: 10;
            left: 50%;
            margin: {
                bottom: -4px;
                left: -8px;
            }
            bottom: 100%;
            width: 16px;
            height: 16px;
            background-color: $teal;
            border: 2px solid white;
            border-radius: 50%;

            @include breakpoint-up(700px) {
                animation: {
                    name: popDateDot;
                    duration: ($duration * 1.1);
                    iteration-count: 1;
                    timing-function: ease;
                }
            }
        }

        @include breakpoint-down(700px) {
            display: none;
        }
    }

    &.hideToday {
        .today {
            display: none;
        }
    }
    
    li.period {
        position: absolute;
        top: 0;
        color: #74c0fc;
        background-color: #339af0;
        height: 8px;
        text-indent: 5px;
        line-height: 58px;
        font-size: 14px;
        white-space: nowrap;
        
        span {
            display: none;
        }
    }
}

@keyframes popDate {
	0% {
        max-height: 0;
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
	
	100% {
		max-height: 255px;
	}
}

@keyframes popText {
    0% {
        color: transparent;
    }

    33% {
        color: transparent;
    }
    
    90% {
        color: inherit;
    }
}

@keyframes popDateDot {
	0% {
        transform: scale(0.75);
    }
    
    50% {
        transform: scale(1.333);
    }
	
	100% {
        transform: scale(1);
	}
}

@keyframes popDateTriangle {
    0% {
        border-width: 0;
    }
	
	100% {
        border-width: 8px;
	}
}

@keyframes popDateInnerBorder {
    0% {
        border-color: transparent;
    }
	
	100% {
        border-color: #ced4da;
    }
}
