/* Typography */

// Fonts
@font-face {
  font-family: "Proxima Nova";
  src: url("/assets/fonts/ProximaNova-Regular.woff2") format("woff2"),
    url("/assets/fonts/ProximaNova-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("/assets/fonts/ProximaNova-Extrabld.woff2") format("woff2"),
    url("/assets/fonts/ProximaNova-Extrabld.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova SC";
  src: url("/assets/fonts/ProximaNovaS-Regular.woff2") format("woff2"),
    url("/assets/fonts/ProximaNovaS-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova SC";
  src: url("/assets/fonts/ProximaNovaS-Extrabld.woff2") format("woff2"),
    url("/assets/fonts/ProximaNovaS-Extrabld.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "YorkTen Slab";
  src: url("/assets/fonts/YorkTen-Slab-NorExB.woff2") format("woff2"),
    url("/assets/fonts/YorkTen-Slab-NorExB.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/inter-v3-latin-regular.woff2") format("woff2"),
    url("/assets/fonts/inter-v3-latin-regular.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Proxima Nova", Roboto, sans-serif;
}

// Typo
.link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

// Titles
h2.page-title {
  display: flex;
  color: $teal;
  //white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-right: 10px;
  align-items: center;
  font-weight: 500;

  svg {
    align-self: flex-start;
    margin-top: 4px;
  }

  & > * {
    margin-right: 8px;
  }
}

h1 > svg,
h2 > svg,
h3 > svg,
h4 > svg,
h5 > svg,
h6 > svg {
  @extend .iconHeading;
}

a {
  color: $blue;
}
