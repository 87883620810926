.overviewContainer {
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(116, 127, 139, 0.2);
    padding: 20px 32px;
    margin-bottom: 50px;
}

.metaDetailContainer {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    padding: 10px 0px;
}

.episodicMetaDetailContainer {
    width: 100%;
    display: flex;
    padding: 10px 0px;
}

.metaDetail {
    display: flex;
    flex-direction: column;
    font-size: 17px;
    width: 35%;
}

.metaDetailLabel {
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    color: #606060;
}

.metaDetailContent {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    color: #2E2E2E;
    margin-bottom: 20px;
}
