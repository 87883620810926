/* Extends */

// Center elements
%center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

%centerAbsolute {
    position: absolute;
    @extend %center;
}

%centerFixed {
    position: fixed;
    @extend %center;
}
