@import "../../abstracts";

.notification {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    transition: color 0.05s linear;
    border-left: 6px solid rgba(0, 0, 0, 0.11);
    white-space: normal;

    &:hover {
        background-color: #fafafa;
    }

    div {
      outline: none;
    }

    & + & {
      border-top: 1px solid rgba(0, 0, 0, 0.11);
    }
  
    &.RED {
      border-left-color: $red;
    }
  
    &.AMBER {
      border-left-color: $yellow;
    }
  
    &.GREEN {
      border-left-color: $green;
    }
  
    &.viewed {
      color: rgba(0, 0, 0, 0.5);
      border-left-color: rgba(0, 0, 0, 0.055);
  
      &:hover {
        color: inherit;
      }
  
      &.RED {
        border-left-color: rgba($red, 0.5);
      }
    
      &.AMBER {
        border-left-color: rgba($yellow, 0.5);
      }
    
      &.GREEN {
        border-left-color: rgba($green, 0.5);
      }
    }
  
    .notificationHeader {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
    }

    .openIcon,
    .closeIcon {
        position: absolute;
        transition: opacity 0.166s linear;
        fill: $grey;
        color: $grey;
    }

    .openIcon {
        right: 12px;
        bottom: 12px; 
    }

    .closeIcon {
        right: 13px;
        bottom: 13px;
        width: 0.9rem;
        height: 0.9rem;
        opacity: 0;
    }

    &.open {
        .openIcon {
            opacity: 0;
        }

        .closeIcon {
            opacity: 1;
        }
    }

    .notificationMessage {
        margin-top: 8px;
        width: calc( 100% - 20px );
    }
  
    svg {
      font-size: 16px;
      opacity: 0.65;
    }
  
    b {
      max-width: 250px;
    }
  
    i {
      margin-left: auto;
      font-size: 0.85em;
    }

    .notificationDetails {
        position: relative;
        max-height: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        transition:
            max-height 0.2s ease,
            padding 0.2s ease;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            height: 0;
            width: 100%;
            border-top: 1px dashed rgba(0, 0, 0, 0.11);
        }

        h1 {
            font: {
                weight: bold;
                size: 0.965rem;
            }
        }

        p {
            font-size: 0.965rem;
        }
    }

    &.open {
        .notificationDetails {
            padding: {
                top: 16px;
                bottom: 40px;
            }
            max-height: 400px;
        }
    }

    .closeDetails {
        position: absolute;
        left: -16px;
        bottom: 0;
        width: calc( 100% + 36px );
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f5f5f5;
        cursor: pointer;
    }

    &:hover .closeDetails,
    .closeDetails:hover {
        background-color: #f1f1f1;
    }

    .notificationActions {
        align-self: flex-end;
    }
}
