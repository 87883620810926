@import '../../abstracts';

.selectWithClose {
  ul {
    padding-bottom: 0;
  }
  
  .closeSelect {
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px; 
    text-align: center;
    line-height: 40px;
    background-color: darken($white, 2.5%);
    outline: none;
    cursor: pointer;

    &,
    & svg {
      transition:
        color 0.125s linear,
        fill 0.125s linear;
    }

    &.inactive {
      &,
      & svg {
        color: lighten($grey, 33%);
        fill: lighten($grey, 33%);
      }
    }
  }
}
