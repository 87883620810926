@import "../../abstracts";

$borderColor: #d5d5d5;

$status-neutral: $grey;
$status-safe: $green;
$status-alert: $yellow;
$status-danger: $red;

.bt {
    border-top: 1px solid $borderColor !important;
}

.bb {
    border-bottom: 1px solid $borderColor !important;
}

.bl {
    border-left: 1px solid $borderColor !important;
}

.br {
    border-right: 1px solid $borderColor !important;
}

/* Notifications */

.notifications,
.notificationsAccordion {

    .notificationsVMenu {
        min-height: 284px;

       
    }

    .notificationsMenuItem p {
        font-size: 16px;
    }

    .notificationsStatusMenu {
        button {
            border-radius: 0;

            position: relative;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                display: block;
                width: 100%;
                height: 2px;
            }

            &.active {
                background-color: rgba($black, 0.1);
            }

            &.all {
                &:after {
                    background-color: $grey;
                }
            }

            &.RED {
                &:after {
                    background-color: $red;
                }
            }

            &.AMBER {
                &:after {
                    background-color: $yellow;
                }

            }

            &.GREEN {
                &:after {
                    background-color: $green;
                }
            }
        }

        button:first-child {
            border-radius: 4px 0 0 4px;
        }

        button:last-child {
            border-radius: 0 4px 4px 0;
        }

        button + button {
            border-left: 0;
        }
    }

    .notificationsList {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .noNotificationmessage {
        margin: auto;
        text-align: center;
    }
}
